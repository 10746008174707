import React from "react";
import {useRoutes} from 'react-router-dom';
import {Provider} from "react-redux";

import Main from "./views/main/Main";
import {store} from "./store/store";

import {SettingsProvider} from './contexts/SettingsContext';
import {AuthProvider} from './contexts/JWTAuthContext';

import routes from './routes';
import '../fake-db';

import "./App.scss";

function App() {
  const content = useRoutes(routes);

  return (
    // <div>
    //   {content}
    // </div>
    <Provider store={store}>
      <Main/>
      {/*<AuthProvider>*/}
      {/*  {content}*/}
      {/*</AuthProvider>*/}
    </Provider>
    // <SettingsProvider>
    //   {content}
    // </SettingsProvider>
    // <Provider store={store}>
    //   <Main/>
    // </Provider>
  );
}

export default App;
