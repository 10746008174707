import "./GameActionCard.scss";
import DownRibbon from "../../../../BaseComponent/ribbon-components/down-ribbon/DownRibbon";
import {MouseEventHandler} from "react";

interface GameActionCardProps {
  title: string;
  imageSrc?: string;
  imageAlt: string;
  color?: 'darkblue'|'green'|'purple'|'red';
  isBuilding?: boolean;
  remainingCount?: number;
  onClick?: MouseEventHandler;
  additionalClassName?: string;
}

const GameActionCard = ({title, imageSrc, imageAlt, color, isBuilding = false, remainingCount, onClick, additionalClassName}: GameActionCardProps) => {
  return (
    <div
      className={'main-action-card-component'
        + (color ? ' main-action-card-color-' + color : '')
        + (isBuilding ? ' main-action-card-building' : '')
        + (additionalClassName ? ' ' + additionalClassName : '')}
      onClick={onClick}
    >
      {remainingCount !== undefined && remainingCount === 0 &&
        <DownRibbon
          label={"V"}
          isSuccess={true}
        />
      }
      {remainingCount !== undefined && remainingCount > 0 &&
        <DownRibbon
          label={remainingCount.toString()}
          isSuccess={false}
        />
      }
      {isBuilding &&
        <div
          className={'main-action-card-header card-header'}
        >En construction</div>
      }
      {imageSrc &&
        <div
          className={'main-action-card-img-container'}
        >
          <img
            className={'main-action-card-img'}
            src={imageSrc}
            alt={imageAlt}
          />
        </div>
      }
      <div
        className={'main-action-card-title'}
      >{title}</div>
    </div>
  );
};

export default GameActionCard;
