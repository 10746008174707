import "./DownRibbon.scss";

interface DownRibbonProps {
    label: string;
    isSuccess: boolean;
}

const DownRibbon = ({ label, isSuccess }: DownRibbonProps) => {
    return (
        <div className={isSuccess ? 'ribbon ribbon-down ribbon-success' : 'ribbon ribbon-down'}>
            <div className={'content'}>
                { label }
            </div>
        </div>
    );
};

export default DownRibbon;
