import * as React from "react"

import {useAppDispatch} from "../../../../store/hooks"

import PageHeader from "../../../PageComponent/LayoutComponent/PageHeader/PageHeader"
import PlayerGroup from "../../../AppComponent/Player/PlayerGroup/PlayerGroup"

import {
    goToHome,
} from "../../../../store/main/main.slice"

import "./AdventurerGroup.scss"

const AdventurerGroup = () => {
    const dispatch = useAppDispatch()

    const goBackAction = () => {
        dispatch(goToHome())
    };

    return (
        <div className={'main-content adventurer-group'}>
            <div className={'main-page-content-container'}>
                <PageHeader
                    label={"Groupe d'aventuriers"}
                    backLabel={"Retour à la cité impériale"}
                    backOnclick={goBackAction}
                />
                <div className={'page-content'}>
                    <div className={'container-fluid'}>
                        <PlayerGroup />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdventurerGroup
