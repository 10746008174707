import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";

interface TrainState {
    page: string;
}

const initialState: TrainState = {
    page: 'train',
};

export const trainSlice = createSlice({
    name: "train",
    initialState,
    reducers: {
        goToTrainHomePage: (state) => {
            state.page = 'train';
        },
        goToCombatTraining: (state) => {
            state.page = 'combat_training';
        },
        goToMeditation: (state) => {
            state.page = 'meditation';
        },
        goToMarket: (state) => {
            state.page = 'market';
        },
    },
});

export const {
    goToTrainHomePage,
    goToCombatTraining,
    goToMeditation,
    goToMarket,
} = trainSlice.actions;

export const getCurrentTrainPage = (state: RootState) => state.train.page;

export default trainSlice.reducer;
