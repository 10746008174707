import {CoachPlayer} from "../../../store/coach/player-list/CoachPlayerList.slice";

export const getCoachPlayersInDatabase = (): CoachPlayer[] => {
  return [
    {
      id: 1,
      pseudo: 'Sangoku',
      avatar: '/player/avatar/avatar_sangoku.jpg',
      fullName: 'David Laroche',
      progress: {},
      successfulWeeks: 3,
      actions: [],
    },
    {
      id: 2,
      pseudo: 'Aragorn',
      avatar: '/player/avatar/avatar_player.png',
      fullName: 'Tony Robbins',
      progress: {},
      successfulWeeks: 3,
      actions: [],
    },
    {
      id: 3,
      pseudo: 'Batman',
      avatar: '/player/avatar/avatar_batman.jpg',
      fullName: 'Andrew Ferebee',
      progress: {},
      successfulWeeks: 2,
      actions: [],
    },
    {
      id: 4,
      pseudo: 'Invincible',
      avatar: '/player/avatar/avatar_invincible.jpeg',
      fullName: 'David Loggins',
      progress: {},
      successfulWeeks: 5,
      actions: [],
    },
    {
      id: 5,
      pseudo: 'Inpiring Light',
      avatar: '/player/avatar/avatar_player.png',
      fullName: 'David Le Francois',
      progress: {},
      successfulWeeks: 1,
      actions: [],
    },
  ]
}
