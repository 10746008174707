import {MouseEventHandler} from "react";

import {
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

import Button from "../../../BaseComponent/ButtonComponent/Button/Button";

import "./PageHeader.scss";

interface PageHeaderProps {
  label: string;
  align?: 'start'|'center'|'end';
  backLabel?: string;
  backOnclick?: MouseEventHandler;
}

const PageHeader = ({label, align, backLabel, backOnclick}: PageHeaderProps) => {
  align = align ?? 'center';
  return (
    <div className={'page-header page-header-align-' + align}>
      {backLabel && backOnclick && align === 'center' &&
        <div className={'page-header-start'}>
          <Button
            variant={'secondary'}
            size={'lg'}
            className={'btn-rounded'}
            gradiant={true}
            onClick={backOnclick}
            icon={faChevronLeft}
            dataAos="fade-right"
          >{backLabel}</Button>
        </div>
      }
      <div
        className={'page-header-title-container'}
        data-aos="fade-down"
        data-aos-offset="0"
      >
        <h1 className={'page-header-title'}>{label}</h1>
      </div>
      {backLabel && backOnclick && align !== 'center' &&
        <div>
          <Button
            variant={'secondary'}
            size={'lg'}
            className={'btn-rounded'}
            gradiant={true}
            onClick={backOnclick}
            icon={faChevronLeft}
            dataAos="fade-right"
          >{backLabel}</Button>
        </div>
      }
      {backLabel && backOnclick && align === 'center' &&
        <div className={'page-header-end'}></div>
      }
    </div>
  );
};

export default PageHeader;
