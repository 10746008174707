import * as React from "react";
import * as ReactBootstrap from "react-bootstrap";

import "./Dropdown.scss";

interface DropdownProps extends ReactBootstrap.DropdownProps {
}

const Dropdown = (
  {
    children,
    className,
  }: DropdownProps
) => {
  return (
    <ReactBootstrap.Dropdown
      className={className}
    >
      {children}
    </ReactBootstrap.Dropdown>
  );
};

export default Dropdown;
