import * as React from "react";
import * as ReactBootstrap from "react-bootstrap";

import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import "./Button.scss";

interface ButtonProps extends ReactBootstrap.ButtonProps {
  gradiant?: boolean;
  icon?: IconDefinition;
  dataAos?: string;
  dataAosOffset?: string;
  dataAosDelay?: string;
}

const Button = (
  {
    children,
    className,
    onClick,
    size,
    type,
    variant,
    gradiant = false,
    icon,
    dataAos,
    dataAosOffset = '0',
    dataAosDelay = '0'
  }: ButtonProps
) => {
  return (
    <ReactBootstrap.Button
      className={className + (gradiant ? ' btn-gradiant' : '')}
      size={size}
      type={type}
      variant={variant}
      onClick={onClick}
      data-aos={dataAos}
      data-aos-offset={dataAosOffset}
      data-aos-delay={dataAosDelay}
    >
      {icon &&
        <FontAwesomeIcon
          icon={icon}
        />
      }
      {children}
    </ReactBootstrap.Button>
  );
};

export default Button;
