import {useAppDispatch} from "../../../store/hooks";

import PageHeader from "../../PageComponent/LayoutComponent/PageHeader/PageHeader";

import {
    goToHome,
} from "../../../store/main/main.slice";

import "./Enjoy.scss";

const Enjoy = () => {
    const dispatch = useAppDispatch();
    return (
        <div className={'main-content enjoy'}>
            <div className={'main-page-content-container'}>
                <PageHeader label={"S'amuser"} backLabel={'Retour'} backOnclick={() => dispatch(goToHome())} />
                <div className={'page-content'}>
                    Contenu à venir
                </div>
            </div>
        </div>
    );
};

export default Enjoy;
