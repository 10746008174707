import {useEffect} from "react";

import {useAppDispatch, useAppSelector} from "../../../../store/hooks";

import {
  faClose,
} from "@fortawesome/free-solid-svg-icons";

import {
  setNotificationDisplayState,
  getNotificationDisplayState,
  getNotificationForceDisplayState,
  getNotificationMessages,
  getNotificationDisplayedMessages,
} from "../../../../store/NotificationList/NotificationList.slice";

import Button from "../../../BaseComponent/ButtonComponent/Button/Button";

import RewardItems from "../../../Module/Reward/RewardItems";

import "./NotificationList.scss";

interface NotificationListProps {
  isDisplayMessages?: boolean;
  hasCloseButton?: boolean;
}

const NotificationList = ({isDisplayMessages = false, hasCloseButton = false}: NotificationListProps) => {
  const dispatch = useAppDispatch();

  const notificationDisplayState = useAppSelector(getNotificationDisplayState);
  const notificationForceDisplayState = useAppSelector(getNotificationForceDisplayState);
  const notificationMessages = useAppSelector(getNotificationMessages);
  const notificationDisplayedMessages = useAppSelector(getNotificationDisplayedMessages);
  // const notificationMessageChanges = useAppSelector(getNotificationMessageChanges);

  const messages = isDisplayMessages ? notificationDisplayedMessages : notificationMessages;
  let reversedMessages = [...messages]
  reversedMessages.reverse()

  const closeNotificationList = () => {
    dispatch(setNotificationDisplayState('hidden'));
  }

  useEffect(() => {
    if (notificationDisplayedMessages.length === 0) {
      dispatch(setNotificationDisplayState('hidden'));
    }
  }, [notificationDisplayedMessages]);

  return (
    <div
      className={
        'main-notification-component'
        + (notificationDisplayState === 'hidden' ? ' main-notification-component-hidden' : '')
        + (notificationForceDisplayState === 'hidden' ? ' main-notification-component-forced-hidden' : '')
        + (notificationForceDisplayState === 'displayed' ? ' main-notification-component-forced-displayed' : '')
      }
    >
      {hasCloseButton &&
        <div
          className={'main-notification-close-container'}
        >
          <Button
              variant={'primary'}
              size={'lg'}
              className={'btn-rounded main-notification-close-button'}
              gradiant={true}
              onClick={() => closeNotificationList()}
              icon={faClose}
              dataAos="fade-down"
              data-aos-offset="0"
          >{""}</Button>
        </div>
      }
      <div
        className={'main-notification-message-list'}
      >
        {messages.map((notificationMessage, index) => {
          return (
            <div
              key={index}
              className={'main-notification-message'}
              data-aos={isDisplayMessages ? "fade-left" : null}
              data-aos-offset={isDisplayMessages ? "0" : null}
            >
              {notificationMessage.img &&
                <div className={'main-notification-message-image'}>
                  <img className={'main-notification-message-img'}
                       src={notificationMessage.img}
                       alt={'Notification image'}
                  />
                </div>
              }
              <div
                className={'main-notification-message-content'}
              >
                {notificationMessage.title &&
                  <div className={'main-notification-message-content-title'}>
                    {notificationMessage.title}
                  </div>
                }
                <div
                  className={'main-notification-message-content-body'}
                  dangerouslySetInnerHTML={{ __html: notificationMessage.message }}
                ></div>
                {notificationMessage.reward &&
                  <div className={'main-notification-message-content-reward'}>
                    <RewardItems
                      additionalClassName={'reward-items-small'}
                      diamonds={notificationMessage.reward.diamonds}
                      goldCoins={notificationMessage.reward.goldCoins}
                      experience={notificationMessage.reward.experience}
                    />
                  </div>
                }
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default NotificationList;
