import * as React from "react"
import {useEffect} from "react";
import * as ReactBootstrap from "react-bootstrap"

import {useAppDispatch, useAppSelector} from "../../../../store/hooks";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faCrown,
  faFireFlameCurved,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

import {
  Player,
  PlayerEvent,
  PlayerEventAction,
  getPlayers,
  getPlayersInDb,
  getSelectedPlayer,
  getEvents,
  getEventsInDb,
  setPlayersToState,
  setSelectedPlayerToState,
  setEventsToState,
  addEventToState,
  removePlayerEventToState,
} from "../../../../store/PlayerGroup/PlayerGroup.slice";

import {getTranslatedDate} from "../../../../utils/date-utils";

import Button from "../../../BaseComponent/ButtonComponent/Button/Button";
import PlayerCard from "../PlayerCard/PlayerCard";

import "./PlayerGroup.scss"

const PlayerGroup = () => {
  const dispatch = useAppDispatch()

  const playersInDb = useAppSelector(getPlayersInDb)
  const players = useAppSelector(getPlayers)
  const selectedPlayer = useAppSelector(getSelectedPlayer)

  const loggedPlayerInDb = playersInDb.find(playerInDb => playerInDb.isLoggedUser);
  const isSelectedPlayerLoggedPlayer = selectedPlayer?.id === loggedPlayerInDb?.id

  const eventsInDb = useAppSelector(getEventsInDb)
  const events = useAppSelector(getEvents)
  let reversedEvents = [...events]
  reversedEvents.reverse()

  const setSelectedPlayer = (player: Player|null) => {
    dispatch(setSelectedPlayerToState(player))
  }

  const addEventToGroupHistory = (player: Player, playerEvent: PlayerEvent, playerEventAction: PlayerEventAction) => {
    if (!loggedPlayerInDb) {
      return
    }

    if (playerEventAction.addPlayerGroupEvent) {
      dispatch(addEventToState({
        date: new Date().toString(),
        playerPseudo: loggedPlayerInDb.pseudo,
        message: playerEventAction.addPlayerGroupEvent.message,
      }))
    }

    dispatch(removePlayerEventToState({
      player: player,
      playerEvent: playerEvent
    }))
  }

  useEffect(() => {
    dispatch(setPlayersToState(playersInDb))
    const selectedPlayerFromDb = loggedPlayerInDb ? loggedPlayerInDb : null;
    setSelectedPlayer(selectedPlayerFromDb)

    dispatch(setEventsToState(eventsInDb))
  }, [])

  return (
    <div className={'player-group-container'}>
      <div className={'player-group-info'}>
        <div className={'player-group-info-image'}>
          <img className={'player-group-info-imag-img'}
               src={"/player_group/avatar/avatar_group.jpg"}
               alt={'Adventurer group image'}
               width={100}
               height={100}
          />
        </div>
        <div className={'player-group-info-name'}>{"Communauté de l'anneau"}</div>
      </div>
      <div className={'player-group-bonuses-and-actions'}>
        <div className={'player-group-active-bonuses'}>
          <div className={'player-group-active-bonuses-label'}>
            Bonus actifs :
          </div>
          <div className={'player-group-active-bonuses-list'}>
            <ReactBootstrap.Badge bg={'success'}>
              +25% Expérience
            </ReactBootstrap.Badge>
            <ReactBootstrap.Badge bg={'danger'}>
              On Fire
              <FontAwesomeIcon
                icon={faFireFlameCurved}
                fixedWidth
              />
            </ReactBootstrap.Badge>
          </div>
        </div>
        <div className={'player-group-actions'}>
          <Button
            variant={'primary'}
            size={'lg'}
            className={'btn-rounded'}
            gradiant={true}
          >{"Lancer Défi"}</Button>
          <Button
            variant={'primary'}
            size={'lg'}
            className={'btn-rounded'}
            gradiant={true}
          >{"Jouer Ensemble"}</Button>
          <Button
            variant={'primary'}
            size={'lg'}
            className={'btn-rounded'}
            gradiant={true}
          >{"Communiquer"}</Button>
          <Button
            variant={'primary'}
            size={'lg'}
            className={'btn-rounded'}
            gradiant={true}
          >{"Saisir Intéraction"}</Button>
          {/*<ReactBootstrap.Dropdown>*/}
          {/*    <ReactBootstrap.Dropdown.Toggle*/}
          {/*        id="player-group-actions"*/}
          {/*        className={'btn-lg btn-rounded btn-gradiant'}*/}
          {/*        variant={'primary'}*/}
          {/*    >*/}
          {/*        Actions*/}
          {/*    </ReactBootstrap.Dropdown.Toggle>*/}
          {/*    <ReactBootstrap.Dropdown.Menu>*/}
          {/*        <ReactBootstrap.Dropdown.Item href="#">Lancer Défi</ReactBootstrap.Dropdown.Item>*/}
          {/*        <ReactBootstrap.Dropdown.Item href="#">Jouer Ensemble</ReactBootstrap.Dropdown.Item>*/}
          {/*        <ReactBootstrap.Dropdown.Item href="#">Communiquer</ReactBootstrap.Dropdown.Item>*/}
          {/*        <ReactBootstrap.Dropdown.Item href="#">Saisir Intéraction</ReactBootstrap.Dropdown.Item>*/}
          {/*    </ReactBootstrap.Dropdown.Menu>*/}
          {/*</ReactBootstrap.Dropdown>*/}
        </div>
      </div>
      <div className={'player-group-team'}>
        <div className={'player-group-team-players row g-3 mt-0 mb-3'}>
          {players.map((player, playerIndex) => {
            const isActivePlayer = player.id === selectedPlayer?.id
            return (
              <div
                key={playerIndex}
                className={'col-6 col-sm-4 col-md-3 col-lg-2 col-xxl-1'}
              >
                <PlayerCard
                  player={player}
                  isActivePlayer={isActivePlayer}
                  // dataAos={'fade-left'}
                  onClick={!isActivePlayer ? () => setSelectedPlayer(player) : undefined}
                />
              </div>
            )
          })}
          <div className={'col-6 col-sm-4 col-md-3 col-lg-2 col-xxl-2'}>
            <div className={'player-group-team-player-action'}>
              <Button
                variant={'secondary'}
                size={'lg'}
                className={'btn-rounded'}
                gradiant={true}
                icon={faUserPlus}
                dataAos="fade-left"
                dataAosOffset={'0'}
              >{"Inviter"}</Button>
            </div>
          </div>
        </div>
        {selectedPlayer &&
          <div className={'player-group-team-player-info row g-2 mt-0 mb-2'}>
            <div className={'col-12 col-md-4 col-lg-2'}>
              <div className={'player-group-team-player-info-details'}>
                <div className={'player-group-team-player-info-detail-pseudo'}>
                  {selectedPlayer.isLeader &&
                    <FontAwesomeIcon
                      icon={faCrown}
                      fixedWidth
                    />
                  }
                  <span>{selectedPlayer.pseudo}</span>
                </div>
                <div className={'player-group-team-player-info-detail-avatar'}>
                  <img
                    className={''}
                    src={selectedPlayer.avatar}
                    alt={selectedPlayer.pseudo + ' avatar'}
                    width={100}
                    height={100}
                  />
                </div>
                <div className={'player-group-team-player-info-detail-level'}>
                  <span className={'player-group-team-player-info-label'}>Niveau : </span>
                  <span>{selectedPlayer.level}</span>
                </div>
                <div className={'player-group-team-player-info-detail-energy'}>
                  <span className={'player-group-team-player-info-label'}>Énergie : </span>
                  <span>{selectedPlayer.energy}%</span>
                </div>
                <div className={'player-group-team-player-info-detail-weekly-progression'}>
                  <span className={'player-group-team-player-info-label'}>Progression hebdomadaire : </span>
                  <span>{selectedPlayer.weeklyProgression}%</span>
                </div>
              </div>
            </div>
            <div className={'col-12 col-md-8 col-lg-10'}>
              <div className={'player-group-team-player-info-actions-and-information'}>
                <ReactBootstrap.Tabs
                  defaultActiveKey="player_actions"
                  id="player-actions-and-information"
                  className="player-group-team-player-info-actions-and-information-tabs"
                >
                  <ReactBootstrap.Tab eventKey="player_actions" title={'Actions' + ' (' + selectedPlayer.events.length + ')'}>
                    <div className={'player-group-team-player-events'}>
                      {selectedPlayer.events.length === 0 &&
                        <div className={'player-group-team-player-no-event'}>
                          Il n'y a actuellement aucune action pour cet aventurier.
                        </div>
                      }
                      {selectedPlayer.events.map((playerEvent, playerEventIndex) => {
                        return (
                          <div
                            key={playerEventIndex}
                            className={'player-group-team-player-event row g-0'}
                            data-aos={'fade-left'}
                            data-aos-easing={'linear'}
                            data-aos-duration={'500'}
                            data-aos-offset={'0'}
                          >
                            <div className={'col-12 col-md-2'}>
                              <div className={'player-group-team-player-event-date'}>
                                {getTranslatedDate(playerEvent.date)}
                              </div>
                            </div>
                            <div className={'col-12 col-md-6'}>
                              <div className={'player-group-team-player-event-label'}>
                                {playerEvent.label}
                              </div>
                            </div>
                            <div className={'col-12 col-md-4'}>
                              <div className={'player-group-team-player-event-actions'}>
                                {!isSelectedPlayerLoggedPlayer && playerEvent.actions.map((playerEventAction, playerEventActionIndex) => {
                                  return (
                                    <div
                                      key={playerEventActionIndex}
                                      className={'player-group-team-player-event-action'}
                                    >
                                      <Button
                                        variant={playerEventAction.type === 'ignore' ? 'secondary' : 'primary'}
                                        className={'btn-rounded'}
                                        gradiant={true}
                                        onClick={() => addEventToGroupHistory(selectedPlayer, playerEvent, playerEventAction)}
                                        dataAos={'fade-left'}
                                        dataAosOffset={'0'}
                                        dataAosDelay={'400'}
                                      >{playerEventAction.label}</Button>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </ReactBootstrap.Tab>
                  <ReactBootstrap.Tab eventKey="player_information" title="Details">
                    <div className={'player-group-team-player-info-information'}>
                      <div className={'row'}>
                        <div className={'col-12 col-md-6'}>
                          <div className={'player-group-team-player-info-information-player-class'}>
                            <span className={'player-group-team-player-info-label'}>Type : </span>
                            <span>{selectedPlayer.information.playerClass}</span>
                          </div>
                        </div>
                        <div className={'col-12 col-md-6'}>
                          <div className={'player-group-team-player-info-information-competence'}>
                                    <span
                                      className={'player-group-team-player-info-label'}>Compétence allouée : </span>
                            <span>{selectedPlayer.information.competence}</span>
                          </div>
                        </div>
                      </div>
                      <div className={'player-group-team-player-info-information-objectives'}>
                        <div className={'player-group-team-player-info-information-objectives-label'}>
                          <span className={'player-group-team-player-info-label'}>Objectifs : </span>
                        </div>
                        <div className={'player-group-team-player-info-information-objective-list'}>
                          {selectedPlayer.information.objectives.map((playerObjective, playerObjectiveIndex) => {
                            return (
                              <div
                                key={playerObjectiveIndex}
                                className={'player-group-team-player-info-information-objective-list-item'}
                              >
                                <div
                                  className={'player-group-team-player-info-information-objective-list-item-category'}
                                >
                                  <span
                                    className={'player-group-team-player-info-label'}
                                  >{'Domaine de vie : '}</span>
                                  <span>{playerObjective.categoryName}</span>
                                </div>
                                <div
                                  className={'player-group-team-player-info-information-objective-list-item-description'}
                                >
                                  <span
                                    className={'player-group-team-player-info-label'}
                                  >{'Description : '}</span>
                                  <span>{playerObjective.description}</span>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </ReactBootstrap.Tab>
                </ReactBootstrap.Tabs>
              </div>
            </div>
          </div>
        }
      </div>

      <div className={'player-group-history-and-leave-action row g-2'}>
        <div className={'col-12 col-md-9'}>
          <div className={'player-group-history'}>
            <div className={'player-group-history-label'}>Historique du groupe</div>
            <div className={'player-group-history-items'}>
              {reversedEvents.length === 0 &&
                <div className={'player-group-history-no-item'}>
                  Il n'y a actuellement aucun historique.
                </div>
              }
              {reversedEvents.map((playerGroupEvent, playerGroupEventIndex) => {
                return (
                  <div
                    key={playerGroupEventIndex}
                    className={'player-group-history-item row g-0'}
                  >
                    <div className={'player-group-history-item-date col-12 col-md-3'}>
                      {getTranslatedDate(playerGroupEvent.date, false, 'except_current_year')}
                    </div>
                    <div className={'player-group-history-item-player-pseudo col-12 col-md-3'}>
                      {playerGroupEvent.playerPseudo}
                    </div>
                    <div className={'player-group-history-item-message col-12 col-md-6'}>
                      {playerGroupEvent.message}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className={'col-12 col-md-3'}>
          <div className={'player-group-leave-action'}>
            <Button
              variant={'secondary'}
              size={'lg'}
              className={'btn-rounded'}
              gradiant={true}
              icon={faArrowRightFromBracket}
            >{"Quitter le groupe"}</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlayerGroup