import {capitalizeFirstChar} from "./string-utils";

export const getTranslatedDate = (
  date: Date|string|null = null,
  withWeekDay: boolean = false,
  withYear: 'except_current_year'|boolean = false,
  withTime: boolean = false,
): string => {
  if (!date) {
    date = new Date()
  } else if (typeof date === 'string') {
    date = new Date(date)
  }

  const weekDay = capitalizeFirstChar(date.toLocaleDateString('fr-FR', {weekday: 'long'}))
  const day = date.toLocaleDateString('fr-FR', {day: 'numeric'})
  const month = capitalizeFirstChar(date.toLocaleDateString('fr-FR', {month: 'long'}))
  const year = date.toLocaleDateString('fr-FR', {year: 'numeric'})

  let translatedDate = ''

  if (withWeekDay) {
    translatedDate += weekDay
  }

  translatedDate += ' ' + day + ' ' + month

  if (withYear) {
    if (withYear === 'except_current_year') {
      const currentYear = new Date().toLocaleDateString('fr-FR', {year: 'numeric'})

      if (currentYear !== year) {
        translatedDate += ' ' + year
      }
    } else {
      translatedDate += ' ' + year
    }
  }

  if (withTime) {
    translatedDate += ', ' + date.toLocaleTimeString('fr-FR')
  }

  return translatedDate.trim()
}
