import {createSlice} from "@reduxjs/toolkit";
import {Dispatch} from "react";

import {RootState} from "../store";

import {RewardData} from "../reward/reward.slice";

export interface NotificationMessage {
  message: string;
  code?: string;
  title?: string;
  img?: string;
  reward?: RewardData;
}

interface NotificationListState {
  displayState: 'displayed'|'hidden'
  forceDisplayState: 'displayed'|'hidden'|null,
  messages: NotificationMessage[];
  displayedMessages: NotificationMessage[];
  messageChanges: number|null,
}

const initialState: NotificationListState = {
  displayState: 'hidden',
  forceDisplayState: null,
  messages: [],
  displayedMessages: [],
  messageChanges: null,
};

export const NotificationListSlice = createSlice({
  name: "notification-list",
  initialState,
  reducers: {
    setNotificationDisplayState: (state, action) => {
      state.displayState = action.payload;
    },
    addNotificationMessageToState: (state, action) => {
      const message = action.payload;
      state.messages = [message, ...state.messages];
      // state.messages.push(message);
    },
    addNotificationDisplayedMessageToState: (state, action) => {
      const message = action.payload;
      // state.displayedMessages = [message, ...state.displayedMessages];
      state.displayedMessages.push(message);
    },
    removeNotificationDisplayedMessageToState: (state, action) => {
      const message = action.payload;
      state.displayedMessages = state.displayedMessages.filter(displayedMessage => displayedMessage.code !== message.code);
    },
    setNotificationForceDisplayState: (state, action) => {
      state.forceDisplayState = action.payload;
    },
    incrementNotificationMessageChanges: (state) => {
      state.messageChanges = (state.messageChanges ?? 0) + 1;
    },
    decrementNotificationMessageChanges: (state) => {
      state.messageChanges = (state.messageChanges ?? 0) - 1;
    },
  }
})

export const {
  setNotificationDisplayState,
  addNotificationMessageToState,
  addNotificationDisplayedMessageToState,
  removeNotificationDisplayedMessageToState,
  setNotificationForceDisplayState,
  incrementNotificationMessageChanges,
  decrementNotificationMessageChanges,
} = NotificationListSlice.actions;

export const getNotificationDisplayState = (state: RootState) => state.notificationList.displayState;
export const getNotificationForceDisplayState = (state: RootState) => state.notificationList.forceDisplayState;
export const getNotificationMessages = (state: RootState) => state.notificationList.messages;
export const getNotificationDisplayedMessages = (state: RootState) => state.notificationList.displayedMessages;
export const getNotificationMessageChanges = (state: RootState) => state.notificationList.messageChanges;

export const addNotificationMessage = (message: NotificationMessage) => (dispatch : Dispatch<any>) => {
  message.code = message.code ?? initCode()

  dispatch(addNotificationMessageToState(message));
  dispatch(addNotificationDisplayedMessageToState(message));
  dispatch(incrementNotificationMessageChanges());
  dispatch(setNotificationDisplayState('displayed'));

  setTimeout(function() {
    dispatch(removeNotificationMessage(message));
  }, 10000);
}

export const removeNotificationMessage = (message: NotificationMessage) => (dispatch : Dispatch<any>, state: RootState) => {
  dispatch(removeNotificationDisplayedMessageToState(message));
}

export const onNotificationMessageEnded = () => (dispatch : Dispatch<any>) => {
  dispatch(decrementNotificationMessageChanges());
}

const initCode = (): string => {
  return (Date.now().toString() + '_' + Math.floor(Math.random() * 1000000))
}

export default NotificationListSlice.reducer;
