import {
  faBolt,
  faHeart,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";

import {CoachRecommendation} from "../../../store/coach/recommendation-list/CoachRecommendationList.slice";

export const getCoachRecommendationsInDatabase = (): CoachRecommendation[] => {
  return [
    {
      id: 1,
      label: 'Encourager Sangoku',
      pseudo: 'Sangoku',
      avatar: '/player/avatar/avatar_sangoku.jpg',
      fullName: 'David Laroche',
      actions: [
        {
          label: 'Encourager',
          icon: faHeart,
        },
      ],
    },
    {
      id: 2,
      label: 'Booster Batman',
      pseudo: 'Batman',
      avatar: '/player/avatar/avatar_batman.jpg',
      fullName: 'Andrew Ferebee',
      actions: [
        {
          label: 'Booster',
          icon: faBolt,
        },
      ],
    },
    {
      id: 3,
      label: 'Féliciter Invincible',
      pseudo: 'Invincible',
      avatar: '/player/avatar/avatar_invincible.jpeg',
      fullName: 'David Loggins',
      actions: [
        {
          label: 'Féliciter',
          icon: faTrophy,
        },
      ],
    },
  ]
}
