import * as React from "react";
import * as ReactBootstrap from "react-bootstrap";

import './Card.scss'

interface CardProps extends ReactBootstrap.CardProps, React.HTMLAttributes<HTMLElement> {
  dataAos?: string;
}

const Card = ({className, children, dataAos, onClick}: CardProps) => {
  className = 'card' + (className ? ' ' + className : '');

  return (
    <ReactBootstrap.Card
      className={className}
      data-aos={dataAos}
      data-aos-easing="linear"
      data-aos-duration="500"
      data-aos-offset="0"
      onClick={onClick}
    >
      {children}
    </ReactBootstrap.Card>
  )
}

export default Card;
