import {useAppDispatch, useAppSelector} from "../../../store/hooks";

import PageHeader from "../../PageComponent/LayoutComponent/PageHeader/PageHeader";
import Button from "../../BaseComponent/ButtonComponent/Button/Button";

import {
  getDailyRewardRewards,
} from "../../../store/user/daily-reward/daily-reward.slice";
import {
  goToHome,
} from "../../../store/main/main.slice";
import {
  setIsDailyRewardReceived,
} from "../../../store/user/user.slice";

import "./DailyReward.scss";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";

const DailyReward = () => {
  const dispatch = useAppDispatch();

  const dailyRewards = useAppSelector(getDailyRewardRewards);

  const returnToHome = () => {
    dispatch(setIsDailyRewardReceived({'isDailyRewardReceived': true}));
    dispatch(goToHome());
  };

  return (
    <div className={'main-content daily-reward-container'}>
      <div className={'main-page-content-container'}>
        <PageHeader label={"Cadeau du jour"}/>
        <div className={'page-content'}>
          <div className={'container'}>
            <div className={'daily-reward row'}>
              <div className={'daily-reward-wheel-container col-6 col-md-6'}>
                <div
                  data-aos="fade-right"
                  data-aos-offset="0"
                >
                  <img className={'daily-reward-wheel-image'}
                       src={'/daily-reward/wheel.png'}
                       alt={'Wheel'}
                  />
                </div>
              </div>
              <div className={'daily-reward-reward-container col-6 col-md-6'}>
                <div className={'daily-reward-reward'}>
                  <div
                    className={'daily-reward-reward-picture'}
                    data-aos="fade-left"
                    data-aos-offset="0"
                  >
                    <img className={'daily-reward-image'}
                         src={'/daily-reward/gem.png'}
                         alt={'Gem'}
                    />
                  </div>
                  <div
                    className={'daily-reward-label'}
                    data-aos="fade-left"
                    data-aos-offset="0"
                  >{'Tu as gagné une gemme !'}</div>
                </div>
                <div
                  className={'daily-reward-action'}
                  data-aos="fade-up"
                  data-aos-offset="0"
                >
                  <Button
                      variant={'primary'}
                      size={'lg'}
                      className={'btn-rounded'}
                      gradiant={true}
                      onClick={returnToHome}
                  >{"Merci"}</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyReward;
