import {
  faCaretRight,
  faCalendarDay,
  faClipboard,
  faUser,
  faHeart,
  faBolt,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";

import {
  CoachPlayer,
  CoachPlayerAction,
} from "../../store/coach/player-list/CoachPlayerList.slice";

import {getCoachPlayersInDatabase} from "../fake-data/coach/players";

export const getCoachPlayersFromDatabase = (): CoachPlayer[] => {

  let coachPlayers = getCoachPlayersInDatabase()

  coachPlayers.forEach((coachPlayer) => {
    addActionToCoachPlayer(coachPlayer)
  })

  return coachPlayers
}

const addActionToCoachPlayer = (coachPlayer: CoachPlayer): CoachPlayer => {

  getCoachPlayerActions().forEach((playerAction: CoachPlayerAction) => {
    coachPlayer.actions.push(playerAction)
  })

  return coachPlayer
}

const getCoachPlayerActions = (): CoachPlayerAction[] => {
  return [
    {
      code: 'sendMessage',
      label: 'Envoyer Message',
      icon: faCaretRight,
    },
    {
      code: 'changeMeeting',
      label: 'Céduler Accompagnant',
      icon: faCalendarDay,
    },
    {
      code: 'addNote',
      label: 'Ajouter Note',
      icon: faClipboard,
    },
    {
      code: 'seeProfile',
      label: 'Voir Profile',
      icon: faUser,
    },
    {
      code: 'encourage',
      label: 'Encourager',
      icon: faHeart,
    },
    {
      code: 'boost',
      label: 'Booster',
      icon: faBolt,
    },
    {
      code: 'congratulate',
      label: 'Féliciter',
      icon: faTrophy,
    },
  ]
}
