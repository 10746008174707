import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";

interface HomeState {
    test: string;
}

// Define the initial state using that type
const initialState: HomeState = {
    test: 'test',
};

export const homeSlice = createSlice({
    name: "home",
    initialState,
    reducers: {
        test: (state) => {


            console.log(state);
        },
    },
});

export const { test } =
    homeSlice.actions;

export const getCurrentPage = (state: RootState) => state.main.page;

export default homeSlice.reducer;
