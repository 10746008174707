import * as React from "react";
import * as ReactBootstrap from "react-bootstrap";

interface DropdownMenuProps extends ReactBootstrap.DropdownMenuProps {
}

const DropdownMenu = (
  {
    children,
    className,
  }: DropdownMenuProps
) => {
  return (
    <ReactBootstrap.Dropdown.Menu
      className={className}
    >
      {children}
    </ReactBootstrap.Dropdown.Menu>
  );
};

export default DropdownMenu;
