import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {RewardData} from "../reward/reward.slice";

export enum Pages {
    // Player pages
    Home = 'home',
    Learn = 'learn',
    Train = 'train',
    Enjoy = 'enjoy',
    Quest = 'quest',

    Profile = 'profile',
    AdventurerGroup = 'adventurer_group',

    // Coach pages
    CoachConsole = 'coach_console',

    // Pop-in contents
    ChallengeList = 'challenge_list',
    Reward = 'reward',
}

export enum PageUserTypes {
    Coach = 'coach',
    Player= 'player',
}

interface MainState {
    page: string;
    pageUserType: PageUserTypes;
    diamonds: number;
    goldCoins: number;
    potions: number;
    experience: number;
}

const initialState: MainState = {
    page: 'home',
    pageUserType: PageUserTypes.Player,
    diamonds: 0,
    goldCoins: 0,
    potions: 0,
    experience: 0,
};

export const mainSlice = createSlice({
    name: "main",
    initialState,
    reducers: {
        goToHome: (state) => {
            state.page = 'home';
            state.pageUserType = PageUserTypes.Player
        },
        goToLearn: (state) => {
            state.page = 'learn';
            state.pageUserType = PageUserTypes.Player
        },
        goToTrain: (state) => {
            state.page = 'train';
            state.pageUserType = PageUserTypes.Player
        },
        goToEnjoy: (state) => {
            state.page = 'enjoy'
            state.pageUserType = PageUserTypes.Player
        },
        goToQuest: (state) => {
            state.page = 'quest'
            state.pageUserType = PageUserTypes.Player
        },

        goToProfile: (state) => {
            state.page = 'profile'
            state.pageUserType = PageUserTypes.Player
        },
        goToAdventurerGroup: (state) => {
            state.page = 'adventurer_group'
            state.pageUserType = PageUserTypes.Player
        },

        goToCoachConsole: (state) => {
            state.page = 'coach_console'
            state.pageUserType = PageUserTypes.Coach
        },

        goToChallengeList: (state) => {
            state.page = 'challenge_list'
            state.pageUserType = PageUserTypes.Player
        },
        goToReward: (state) => {
            state.page = 'reward'
            state.pageUserType = PageUserTypes.Player
        },

        updateMainItems: (state, action: PayloadAction<RewardData>) => {
            const reward = action.payload;
            state.diamonds += reward.diamonds;
            state.goldCoins += reward.goldCoins;
            state.potions += reward.potions;
            state.experience += reward.experience;
        },
    },
});

export const {
    goToHome,
    goToLearn,
    goToTrain,
    goToProfile,
    goToEnjoy,
    goToQuest,
    goToChallengeList,
    goToReward,
    goToAdventurerGroup,
    goToCoachConsole,
    updateMainItems,
} = mainSlice.actions;

export const getCurrentPage = (state: RootState) => state.main.page;
export const getCurrentPageUserType = (state: RootState) => state.main.pageUserType;
export const getDiamonds = (state: RootState) => state.main.diamonds;
export const getGoldCoins = (state: RootState) => state.main.goldCoins;
// export const getPotions = (state: RootState) => state.main.potions;
// export const getExperience = (state: RootState) => state.main.experience;

export default mainSlice.reducer;
