import * as React from "react";
import {useEffect} from "react";

import {useAppDispatch, useAppSelector} from "../../../../store/hooks";

import * as ReactBootstrap from "react-bootstrap";

import {
  getCoachRecommendations,
  setCoachRecommendationsToState
} from "../../../../store/coach/recommendation-list/CoachRecommendationList.slice";
import {
  getCoachRecommendationsFromDatabase,
} from "../../../../utils/coach/recommendation-utils";

import Button from "../../../BaseComponent/ButtonComponent/Button/Button";
import ListGroup from "../../../BaseComponent/ListGroupComponent/ListGroup/ListGroup";

import "./CoachRecommendationList.scss";

const CoachRecommendationList = () => {
  const dispatch = useAppDispatch();

  const coachRecommendationsFromDb = useAppSelector(getCoachRecommendationsFromDatabase)
  const coachRecommendations = useAppSelector(getCoachRecommendations)

  useEffect(() => {
    dispatch(setCoachRecommendationsToState(coachRecommendationsFromDb))
  }, [])

  return (
    <div className={'coach-recommendation-list-component'}>
      <div className={'coach-recommendation-list-container'}>
        <ListGroup
          header={{items: [
              {
                code: 'recommendation',
                label: 'Recommandation',
                size: {
                  md: 4,
                },
              },
              {
                code: 'character_name',
                label: 'Nom Personnage',
                size: {
                  md: 3,
                },
              },
              {
                code: 'name',
                label: 'Nom',
                size: {
                  md: 3,
                },
              },
              {
                code: 'actions',
                label: 'Actions',
                align: 'end',
                size: {
                  md: 2,
                },
              },
            ]}}
        >
          {coachRecommendations.map((recommendation, recommendationIndex) => {
            return (
              <ReactBootstrap.ListGroup.Item
                key={recommendationIndex + 1}
                className={'coach-recommendation-list-group-item'}>
                <ReactBootstrap.Row
                  className={'g-0 list-group-item-container'}
                >
                  <ReactBootstrap.Col
                    md={4}
                  >
                    <div className={'list-group-item-label'}>
                      <span>{ recommendation.label }</span>
                    </div>
                  </ReactBootstrap.Col>

                  <ReactBootstrap.Col
                    md={3}
                  >
                    <div className={'list-group-item-avatar-pseudo'}>
                      <div className={'list-group-item-avatar'}>
                        <img className={'list-group-item-avatar-image'}
                             src={recommendation.avatar}
                             alt={'Player avatar'}
                        />
                      </div>
                      <div className={'list-group-item-pseudo'}>{recommendation.pseudo}</div>
                    </div>
                  </ReactBootstrap.Col>

                  <ReactBootstrap.Col
                    md={3}
                  >
                    <div className={'list-group-item-full-name'}>
                      <span>{ recommendation.fullName }</span>
                    </div>
                  </ReactBootstrap.Col>

                  <ReactBootstrap.Col
                    className={'list-group-item-column list-group-item-column-actions'}
                    md={2}
                  >
                    <div className={'list-group-item-actions text-end'}>
                      {recommendation.actions.map((action, actionIndex) => (
                        <Button
                          key={actionIndex}
                          variant={'primary'}
                          size={'lg'}
                          className={'btn-rounded'}
                          gradiant={true}
                          icon={action.icon}
                          dataAos="fade-left"
                        >{ action.label }</Button>
                      ))}
                    </div>
                  </ReactBootstrap.Col>

                </ReactBootstrap.Row>
              </ReactBootstrap.ListGroup.Item>
            )
          })}
        </ListGroup>
      </div>
    </div>
  )
}

export default CoachRecommendationList;
