import {useEffect} from "react";

import {useAppDispatch, useAppSelector} from "../../../store/hooks";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

import PageHeader from "../../PageComponent/LayoutComponent/PageHeader/PageHeader";
import Button from "../../BaseComponent/ButtonComponent/Button/Button";
import ProgressBar from "../../BaseComponent/ProgressBarComponent/ProgressBar/ProgressBar";

import {
  goToHome,
  goToReward,
  updateMainItems,
} from "../../../store/main/main.slice";
import {
  getChallengeCode,
  getChallengeTitle,
  getChallengeRemainingTime,
  getChallengeItems,
  getChallengeIsSuccess,
  getChallengeReward,
  getChallengeRewardOffered,
  validChallengeStep,
  tagChallengeRewardOffered,
  getChallengeSuccessCoachMessage,
} from "../../../store/challenge-list/challenge-list.slice";
import {
  initReward,
} from "../../../store/reward/reward.slice";
import {
  validUserChallengeStep,
} from "../../../store/user/user.slice";
import {setUserCoachMessage} from "../../../store/user/user-coach/user-coach.slice";
import {
  addNotificationMessage,
} from "../../../store/NotificationList/NotificationList.slice";

import "./ChallengeList.scss";

const ChallengeList = () => {
  const dispatch = useAppDispatch();

  const challengeCode = useAppSelector(getChallengeCode);
  const challengeTitle = useAppSelector(getChallengeTitle);
  const challengeRemainingTime = useAppSelector(getChallengeRemainingTime);
  const challengesItems = useAppSelector(getChallengeItems);
  const isChallengeSuccess: boolean = useAppSelector(getChallengeIsSuccess);
  const challengeSuccessCoachMessage = useAppSelector(getChallengeSuccessCoachMessage);
  const challengeReward = useAppSelector(getChallengeReward);
  const challengeRewardOffered = useAppSelector(getChallengeRewardOffered);

  const validChallengeStepAction = (challengeCode: string, index: number) => {
    dispatch(validChallengeStep({'challengeItemIndex': index}));
    dispatch(validUserChallengeStep({'challengeCode': challengeCode, 'challengeItemIndex': index}));

    const challengeItem = challengesItems[index];
    const challengeItemStep = challengeItem.steps[challengeItem.currentStep];
    const challengeItemStepReward = challengeItemStep.reward;
    const coachAvatarImg = '/coach/avatar.png';

    dispatch(updateMainItems(challengeItemStepReward));

    dispatch(addNotificationMessage({
      message: challengeItemStep.successCoachMessage,
      img: coachAvatarImg,
      reward: challengeItemStepReward,
    }));
  }

  useEffect(() => {
    if (!challengeRewardOffered && isChallengeSuccess) {
      dispatch(setUserCoachMessage({
        message: challengeSuccessCoachMessage,
        reward: challengeReward,
        displayType: 'toNotification',
      }));
      dispatch(updateMainItems(challengeReward));
      dispatch(initReward(challengeReward));
      dispatch(goToReward());
      dispatch(tagChallengeRewardOffered());
    }
  }, [dispatch, isChallengeSuccess, challengeReward, challengeRewardOffered, challengeSuccessCoachMessage]);

  return (
    <div className={'main-content challenge-list-container'}>
      <div className={'main-page-content-container'}>
        <PageHeader
          label={challengeTitle}
          align={'start'}
          backLabel={'Retour à la cité impériale'}
          backOnclick={() => dispatch(goToHome())}
        />
        <div className={'page-content'}>
          <div className={'container'}>
            <div>{isChallengeSuccess}</div>
            {!isChallengeSuccess &&
              <div
                className={'challenge-remaining-time-label'}
                data-aos="fade-left"
                data-aos-offset="0"
              >
                {challengeRemainingTime} jours restants
              </div>
            }
            {isChallengeSuccess &&
              <div
                className={'challenge-success-label'}
                data-aos="fade-left"
                data-aos-offset="0"
              >
                Challenge fini
              </div>
            }
            <div className={'challenge-list'}>
              {challengesItems.map((challenge, index) => {
                const challengeProgressPercent = Math.round(challenge.currentStep / challenge.steps.length * 100)
                return (
                  <div
                    className={'challenge-card ' + (challenge.steps.length === 1 ? 'challenge-card-simple' : 'challenge-card-multiple')}
                    key={index}
                    data-aos="fade-down"
                    data-aos-offset="0"
                  >
                    <div className={'card-label'}>
                      {challenge.title}
                    </div>
                    {challenge.isSuccess && challenge.steps.length === 1 &&
                      <div className={'challenge-validated-label'}>
                        <FontAwesomeIcon
                          icon={faCheck}
                          fixedWidth
                        />
                      </div>
                    }
                    <div className={'card-content'}>
                      {challenge.steps.length > 1 && <ProgressBar
                        percentage={challengeProgressPercent}
                        additionalClassName={''}
                      />}
                      {!challenge.isSuccess && challenge.steps.length === 1 &&
                          <Button
                              variant={'primary'}
                              size={'lg'}
                              className={'btn-rounded challenge-btn-simple'}
                              onClick={() => validChallengeStepAction(challengeCode, index)}
                          >{"Confirmer"}</Button>
                      }
                      {!challenge.isSuccess && challenge.steps.length > 1 &&
                          <Button
                              variant={'primary'}
                              size={'lg'}
                              className={'btn-rounded challenge-btn-multiple'}
                              onClick={() => validChallengeStepAction(challengeCode, index)}
                          >{"+1"}</Button>
                      }
                      {challenge.isSuccess && challenge.steps.length > 1 &&
                        <div className={'challenge-validated-label'}>
                          <FontAwesomeIcon
                            icon={faCheck}
                            fixedWidth
                          />
                        </div>
                      }
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChallengeList;
