import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../store";

interface DailyRewardState {
  rewards: [];
}

const initialState: DailyRewardState = {
  rewards: [],
};

export const dailyRewardSlice = createSlice({
  name: "daily-reward",
  initialState,
  reducers: {
    setDailyRewardRewards: (state, action) => {
      state.rewards = action.payload.rewards;
    },
  },
});

export const {
  setDailyRewardRewards,
} = dailyRewardSlice.actions;

export const getDailyRewardRewards = (state: RootState) => state.dailyRewards.rewards;

export default dailyRewardSlice.reducer;
