import {useAppDispatch, useAppSelector} from "../../../store/hooks";

import PageHeader from "../../PageComponent/LayoutComponent/PageHeader/PageHeader";
import Button from "../../BaseComponent/ButtonComponent/Button/Button";
import RewardItems from "../../Module/Reward/RewardItems";

import {
  // goToHome,
  goToChallengeList, goToHome,
} from "../../../store/main/main.slice";
import {
  getRewardDiamonds,
  getRewardExperience,
  getRewardGoldCoins,
  getRewardPotions
} from "../../../store/reward/reward.slice";

// import {goToTrainHomePage} from "../../store/train/train.slice";
import {
  closeUserCoachMessage,
} from "../../../store/user/user-coach/user-coach.slice";


import "./Reward.scss";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";

const Reward = () => {
  const dispatch = useAppDispatch();

  const rewardDiamonds = useAppSelector(getRewardDiamonds);
  const rewardGoldCoins = useAppSelector(getRewardGoldCoins);
  const rewardPotions = useAppSelector(getRewardPotions);
  const rewardExperience = useAppSelector(getRewardExperience);

  const goBackToChallengeList = () => {
    dispatch(closeUserCoachMessage());
    dispatch(goToChallengeList())
  };

  return (
    <div className={'main-content reward-container'}>
      <div className={'main-page-content-container'}>
        <PageHeader label={"Récompense d'une semaine accomplie"}
                    align={'start'}
          // backLabel={"Retour au challenge"}
          // backOnclick={() => dispatch(goToChallengeList())}
        />
        <div className={'page-content'}>
          <div className={'container'}>
            <div className={'reward-items-container-row row'}>
              <div className={'reward-items-container-col col col-md-6'}>
                <div
                  className={'reward-items-container'}
                  data-aos="fade-right"
                  data-aos-offset="0"
                >
                  <img className={'reward-chest-image'}
                       src={'/reward/chest.png'}
                       alt={'Chest'}
                  />
                  <RewardItems
                    diamonds={rewardDiamonds}
                    goldCoins={rewardGoldCoins}
                    experience={rewardExperience}
                  />
                </div>
              </div>
              <div className={'col col-md-6'}>
                <div
                  className={'reward-button'}
                  data-aos="fade-left"
                  data-aos-offset="0"
                >
                  <Button
                      variant={'primary'}
                      size={'lg'}
                      className={'btn-rounded'}
                      gradiant={true}
                      onClick={goBackToChallengeList}
                  >{"Merci"}</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reward;
