import {createSlice} from "@reduxjs/toolkit";

import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

import {RootState} from "../../store";

export interface CoachRecommendation {
  id: number;
  label: string;
  pseudo: string;
  avatar: string;
  fullName: string;
  actions: CoachRecommendationAction[];
}

export interface CoachRecommendationAction {
  label: string;
  icon: IconDefinition|undefined;
}



interface CoachPlayerListState {
  recommendations: CoachRecommendation[];
}

const initialState: CoachPlayerListState = {
  recommendations: [],
};

export const CoachRecommendationListSlice = createSlice({
  name: "coach-recommendation-group",
  initialState,
  reducers: {
    setCoachRecommendationsToState: (state, action) => {
      state.recommendations = action.payload;
    },
  },
})

export const {
  setCoachRecommendationsToState,
} = CoachRecommendationListSlice.actions;

export const getCoachRecommendations = (state: RootState) => state.coachRecommendationGroup.recommendations;

export default CoachRecommendationListSlice.reducer;
