import * as React from "react";
import * as ReactBootstrap from "react-bootstrap";

import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import "./ListGroup.scss";



interface ListGroupHeader {
  items: ListGroupHeaderItem[]
}

interface ListGroupHeaderItem {
  code: string
  label: string
  size: {
    md: number
  }
  align?: 'start'|'center'|'end';
  icon?: IconDefinition;
}



interface ListGroupProps extends ReactBootstrap.ListGroupProps {
  header?: ListGroupHeader;
  dataAos?: string;
  dataAosOffset?: string;
  dataAosDelay?: string;
}

const ListGroup = (
  {
    children,
    className,
    header,
    dataAos,
    dataAosOffset = '0',
    dataAosDelay = '0'
  }: ListGroupProps
) => {
  return (
    <ReactBootstrap.ListGroup
      className={className}
      data-aos={dataAos}
      data-aos-offset={dataAosOffset}
      data-aos-delay={dataAosDelay}
    >
      {header &&
        <ReactBootstrap.Row
          className={'g-0 list-group-header'}
        >
          {header.items.map((headerItem, headerItemIndex) => {
            const headerItemAlign = headerItem.align ? headerItem.align : 'start'

            return (
              <ReactBootstrap.Col
                key={headerItemIndex}
                className={'list-group-header-item ' + ('text-' + headerItemAlign)}
                md={headerItem.size.md}
              >
                <div className={'list-group-header-item-content'}>
                  {headerItem.icon &&
                    <FontAwesomeIcon
                      icon={headerItem.icon}
                    />
                  }
                  <span>{ headerItem.label }</span>
                </div>
              </ReactBootstrap.Col>
            )
          })}
        </ReactBootstrap.Row>
      }
      {children}
    </ReactBootstrap.ListGroup>
  );
};

export default ListGroup;
