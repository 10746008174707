import {createSlice} from "@reduxjs/toolkit";

import {RootState} from "../store";

export interface Player {
  id: number;
  pseudo: string;
  avatar: string;
  level: number;
  energy: number;
  weeklyProgression: number;
  isLeader: boolean;
  isLoggedUser: boolean;
  events: PlayerEvent[];
  information: PlayerInformation;
}

export interface PlayerEvent {
  id: number;
  date: string;
  label: string;
  actions: PlayerEventAction[];
}

export interface PlayerEventAction {
  type: string;
  label: string;
  addPlayerGroupEvent?: PlayerActionAddPlayerGroupEvent;
}

export interface PlayerActionAddPlayerGroupEvent {
  message: string;
}

export interface PlayerInformation {
  playerClass: string;
  competence: string;
  objectives: PlayerObjective[];
}

export interface PlayerObjective {
  categoryName: string;
  description: string;
}

export interface PlayerGroupEvent {
  date: string;
  playerPseudo: string;
  message: string;
}

interface PlayerGroupGroupState {
  players: Player[];
  selectedPlayer: Player | null;
  events: PlayerGroupEvent[];
}

const initialState: PlayerGroupGroupState = {
  players: [],
  selectedPlayer: null,
  events: [],
};

export const PlayerGroupSlice = createSlice({
  name: "player-group",
  initialState,
  reducers: {
    setPlayersToState: (state, action) => {
      state.players = action.payload;
    },
    addPlayerToState: (state, action) => {
      const playerToAdd = action.payload;
      state.players = [playerToAdd, ...state.players];
      // state.players.push(playerToAdd);
    },
    removePlayerToState: (state, action) => {
      const playerToDelete = action.payload;
      state.players = state.players.filter(player => player.id !== playerToDelete.id);
    },
    setSelectedPlayerToState: (state, action) => {
      state.selectedPlayer = action.payload;
    },
    setEventsToState: (state, action) => {
      state.events = action.payload;
    },
    addEventToState: (state, action) => {
      const eventToAdd = action.payload;
      state.events = [...state.events, eventToAdd];
      // state.players.push(playerToAdd);
    },
    removePlayerEventToState: (state, action) => {
      const playerEventData = action.payload;
      const player = playerEventData.player;
      const playerEvent = playerEventData.playerEvent;

      let playersUpdated = state.players
      const playerToUpdate = playersUpdated.find(playerInState => playerInState.id === player.id);

      if (playerToUpdate) {
        // Update player data in players state
        playerToUpdate.events = playerToUpdate.events.filter(playerEventInState => playerEventInState.id !== playerEvent.id);
        state.players = playersUpdated

        // Update player data in selectedPlayer state
        PlayerGroupSlice.caseReducers.setSelectedPlayerToState(state, { type: 'setSelectedPlayerToState', payload: playerToUpdate })
      }
    },
  }
})

export const {
  setPlayersToState,
  setSelectedPlayerToState,
  setEventsToState,
  addEventToState,
  removePlayerEventToState,
} = PlayerGroupSlice.actions;

export const getPlayers = (state: RootState) => state.playerGroup.players;
export const getSelectedPlayer = (state: RootState) => state.playerGroup.selectedPlayer;
export const getEvents = (state: RootState) => state.playerGroup.events;

const eventDateYear = 2024

export const getPlayersInDb = (): Player[] => {
  return [
    {
      id: 1,
      pseudo: 'OrlanduMike',
      avatar: '/player/avatar/avatar_player.png',
      level: 33,
      energy: 80,
      weeklyProgression: 77,
      isLeader: true,
      isLoggedUser: true,
      events: [
        {
          id: 1,
          date: new Date(eventDateYear, 9, 24, 16, 0, 0).toString(),
          label: 'Gagner tournois + Prix objectif Magique',
          actions: [
            {
              type: 'ignore',
              label: 'Ignorer',
            },
            {
              type: 'validate',
              label: 'Féliciter',
              addPlayerGroupEvent: {
                message: "Félicitation envoyées à OrlanduMike pour le tournois du 25 octobre",
              },
            },
          ],
        },
        {
          id: 2,
          date: new Date(eventDateYear, 9, 25, 16, 0, 0).toString(),
          label: 'Demande de soutien 2 Gems Santé',
          actions: [
            {
              type: 'ignore',
              label: 'Ignorer',
            },
            {
              type: 'validate',
              label: 'Donner',
              addPlayerGroupEvent: {
                message: "Don de 2 Gems Santé à OrlanduMike",
              },
            },
          ],
        },
      ],
      information: {
        playerClass: 'Guerrier',
        competence: 'Épées énergisées',
        objectives: [
          {
            categoryName: 'Vocation',
            description: "J'inspire mes collègue à donner le meilleur d'eux-mêmes et à se responsabiliser tout en révélant leur potentiel. Mon optimisme, ma motivation, ma persévérance et mon professionnalisme sont un modèle. Mon écoute me permet d'accompagner mes collègues dans les situations problématiques qu'ils rencontrent. Je m'améliore, je m'amuse, j'expérimente et je contribue à des initiatives impactantes, ce qui me donne de l'énergie et un sentiment d'accomplissement.",
          },
        ],
      },
    },
    {
      id: 2,
      pseudo: 'Arwen',
      avatar: '/player/avatar/avatar_arwen.jpg',
      level: 70,
      energy: 45,
      weeklyProgression: 40,
      isLeader: false,
      isLoggedUser: false,
      events: [
        {
          id: 10,
          date: new Date(eventDateYear, 3, 3, 16, 0, 0).toString(),
          label: 'Gagner tournois + Prix objectif Santé',
          actions: [
            {
              type: 'ignore',
              label: 'Ignorer',
            },
            {
              type: 'validate',
              label: 'Féliciter',
              addPlayerGroupEvent: {
                message: "Félicitation envoyées à Arwen pour le tournois du 3 mars",
              },
            },
          ],
        },
        {
          id: 11,
          date: new Date(eventDateYear, 3, 10, 16, 0, 0).toString(),
          label: 'Demande de soutien 4 Gems Magie',
          actions: [
            {
              type: 'ignore',
              label: 'Ignorer',
            },
            {
              type: 'validate',
              label: 'Donner',
              addPlayerGroupEvent: {
                message: "Don de 4 Gems Magie à Arwen",
              },
            },
          ],
        },
      ],
      information: {
        playerClass: 'Druide',
        competence: 'Énergie céleste',
        objectives: [
          {
            categoryName: 'Couple',
            description: "J'aime mon partenaire de vie tout en ayant le temps de m'accomplir dans mes passions. Nous vivons dans le respect et la confiance avec une belle complicité nous permettant de communiquer aisément nos désirs et besoins. Nous prenons le temps nécessaire pour cultiver notre relation amoureuse en harmonie avec nos vocations respectives. Nous optimisons cette balance tout en réservant du temps pour se ressourcer.",
          },
        ],
      },
    },
    {
      id: 3,
      pseudo: 'Legolas',
      avatar: '/player/avatar/avatar_legolas.jpg',
      level: 50,
      energy: 100,
      weeklyProgression: 100,
      isLeader: false,
      isLoggedUser: false,
      events: [],
      information: {
        playerClass: 'Guerrier',
        competence: 'Vents bénéfiques',
        objectives: [
          {
            categoryName: 'Finance',
            description: "Bénéficier d'une liberté financière me permettant d'entreprendre et soutenir les projets que je désire réaliser sans avoir à solliciter des fonds à d'autres personnes.",
          },
        ],
      },
    },
    {
      id: 4,
      pseudo: 'Gandalf',
      avatar: '/player/avatar/avatar_gandalf.jpg',
      level: 99,
      energy: 65,
      weeklyProgression: 60,
      isLeader: false,
      isLoggedUser: false,
      events: [],
      information: {
        playerClass: 'Mage',
        competence: 'Boule de feu',
        objectives: [
          {
            categoryName: 'Spiritualité',
            description: "Mieux gérer mon nouveau niveau de conscience afin de lâcher prise sur ce qui est moins important tout en étant plus attentif aux messages qui me sont destinés.",
          },
        ],
      },
    },
  ]
}

export const getEventsInDb = (): PlayerGroupEvent[] => {
  return [
    {
      date: new Date((eventDateYear - 1), 11, 2, 8, 15, 34).toString(),
      playerPseudo: 'OrlanduMike',
      message: "Objectif hebdomadaire rempli",
    },
    {
      date: new Date(eventDateYear, 0, 15, 17, 10, 46).toString(),
      playerPseudo: 'Legolas',
      message: "Interaction saisie avec Arwen : Pratique de tir à l'arc",
    },
    {
      date: new Date(eventDateYear, 1, 4, 7, 46, 13).toString(),
      playerPseudo: 'Arwen',
      message: "Objectif hebdomadaire rempli",
    },
    {
      date: new Date(eventDateYear, 2, 25, 13, 55, 42).toString(),
      playerPseudo: 'Gandalf',
      message: "Don de 4 Gems Vocation à Arwen",
    },
    {
      date: new Date(eventDateYear, 3, 15, 10, 24, 12).toString(),
      playerPseudo: 'Legolas',
      message: "Objectif hebdomadaire rempli",
    },
    {
      date: new Date(eventDateYear, 4, 5, 15, 45, 31).toString(),
      playerPseudo: 'Gandalf',
      message: "Objectif hebdomadaire rempli",
    },
    {
      date: new Date(eventDateYear, 5, 16, 9, 33, 45).toString(),
      playerPseudo: 'Legolas',
      message: "Félicitation envoyées à OrlanduMike pour le tournois du 30 avril",
    },
    {
      date: new Date(eventDateYear, 6, 7, 21, 43, 27).toString(),
      playerPseudo: 'OrlanduMike',
      message: "Gain du niveau 33",
    },
  ]
}

export default PlayerGroupSlice.reducer;
