import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";

import {RewardData} from "../reward/reward.slice";
// import {validUserChallengeStep} from "../user/user.slice";
// import {Dispatch} from "react";

export interface ChallengeData {
    code: string;
    title: string;
    remainingTime: number;
    isSuccess: boolean;
    items: ChallengeItemData[];
    successCoachMessage: string;
    reward: RewardData;
}

interface ChallengeItemData {
    title: string;
    currentStep: number;
    isSuccess: boolean;
    steps: ChallengeItemStepData[];
}

interface ChallengeItemStepData {
    description: string;
    successCoachMessage: string;
    reward: RewardData;
}

interface ChallengeListState {
    currentChallenge: ChallengeData;
    rewardOffered: boolean;
    userChallenges: ChallengeData[];
}

const initialState: ChallengeListState = {
    currentChallenge: {
        code: '',
        title: '',
        remainingTime: 0,
        isSuccess: false,
        items: [],
        successCoachMessage: '',
        reward: {
            diamonds : 0,
            goldCoins : 0,
            potions : 0,
            experience : 0,
        },
    },
    rewardOffered: false,
    userChallenges: [],
};

export const challengeListSlice = createSlice({
    name: "challenge-list",
    initialState,
    reducers: {
        initChallenge: (state, action) => {
            const challenge = action.payload.challenge;

            if (challenge) {
                state.currentChallenge = challenge;
                state.rewardOffered = challenge.isSuccess;
            } else {
                // TODO throw error
            }
        },
        initChallengeFromJsonData: (state, action) => {
            const challengeData = action.payload.challengeData;
            state.currentChallenge = challengeData;
            state.rewardOffered = challengeData.isSuccess;
            state.userChallenges.push(challengeData);
        },
        validChallengeStep: (state, action) => {
            const challengeItemIndex = action.payload.challengeItemIndex;
            let currentChallengeItem = state.currentChallenge.items[challengeItemIndex];

            currentChallengeItem.currentStep += 1;
            currentChallengeItem.isSuccess = currentChallengeItem.currentStep === currentChallengeItem.steps.length;

            let currentChallengesItemsToFinish = 0;
            state.currentChallenge.items.forEach((challengeItem) => {
                if (!challengeItem.isSuccess) {
                    currentChallengesItemsToFinish += 1;
                }
            });
            state.currentChallenge.isSuccess = currentChallengesItemsToFinish === 0;
        },
        tagChallengeRewardOffered: (state) => {
            state.rewardOffered = true;
        },
    },
});

export const {
    initChallenge,
    // initChallengeFromJsonData,
    validChallengeStep,
    tagChallengeRewardOffered,
} = challengeListSlice.actions;


// const dispatch = useAppDispatch();
export const getChallengeCode = (state: RootState) => state.challengeList.currentChallenge.code;
export const getChallengeTitle = (state: RootState) => state.challengeList.currentChallenge.title;
export const getChallengeRemainingTime = (state: RootState) => state.challengeList.currentChallenge.remainingTime;
export const getChallengeItems = (state: RootState) => state.challengeList.currentChallenge.items;
export const getChallengeIsSuccess = (state: RootState) => state.challengeList.currentChallenge.isSuccess;
export const getChallengeSuccessCoachMessage = (state: RootState) => state.challengeList.currentChallenge.successCoachMessage;
export const getChallengeReward = (state: RootState) => state.challengeList.currentChallenge.reward;
export const getChallengeRewardOffered = (state: RootState) => state.challengeList.rewardOffered;

export const getChallengeItemsToFinishFromChallenge = (challenge: ChallengeData|null) => (): number => {
    let challengesItemsToFinish = 0;

    if (challenge === null) {
        return 0;
    }

    challenge.items.forEach((challengeItem) => {
        if (!challengeItem.isSuccess) {
            challengesItemsToFinish += 1;
        }
    });

    return challengesItemsToFinish;
};

export const getChallengesItemsToFinishFromChallenges = (challenges: ChallengeData[]) => (): number => {
    let challengesItemsToFinish = 0;

    challenges.forEach(challenge => {
        challenge.items.forEach((challengeItem) => {
            if (!challengeItem.isSuccess) {
                challengesItemsToFinish += 1;
            }
        });
    });

    return challengesItemsToFinish;
};

// export const validChallengeStepAction = (challengeCode: string, index: number) => (dispatch : Dispatch<any>) => {
//     dispatch(validChallengeStep({'challengeItemIndex' : index}));
//     dispatch(validUserChallengeStep({'challengeCode': challengeCode, 'challengeItemIndex' : index}));
// }

export default challengeListSlice.reducer;
