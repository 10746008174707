import {MouseEventHandler} from "react";

import "./ProgressBar.scss";

interface ProgressBarProps {
  percentage: number;
  additionalClassName?: string;
  onclick?: MouseEventHandler;
}

const ProgressBar = ({percentage, additionalClassName, onclick}: ProgressBarProps) => {
  return (
    <div className={'progress-bar-component' + (additionalClassName ? ' ' + additionalClassName : '')}
         data-percentage={percentage}
         onClick={onclick}
    >
      <div className="progress-bar-component-progress"></div>
    </div>
  );
};

export default ProgressBar;
