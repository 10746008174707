import {lazy} from 'react';
import {Navigate} from 'react-router-dom';

import AuthGuard from './auth/AuthGuard';
import {authRoles} from './auth/authRoles';
import Loadable from './components/Loadable';

// Main page
import Main from "./views/main/Main";

// Session page
const NotFound = Loadable(lazy(() => import('./views/sessions/NotFound')));
const JwtLogin = Loadable(lazy(() => import('./views/sessions/JwtLogin')));


const routes = [
  {
    element: (
      // <div>TEST</div>
      // <Main/>
      <AuthGuard>
        {/*<div>TEST</div>*/}
        <Main/>
        {/*<MatxLayout />*/}
      </AuthGuard>
    ),
    children: [
      // ...materialRoutes,
      // dashboard route
      {
        path: '/main',
        element: <Main/>,
        auth: authRoles.player
      },

      // e-chart route
      // {
      //   path: '/charts/echarts',
      //   element: <AppEchart />,
      //   auth: authRoles.editor
      // }
    ]
  },

  // session pages route
  {path: '/session/404', element: <NotFound/>},
  {path: '/session/signin', element: <JwtLogin/>},

  // redirections otherwise
  {path: '/', element: <Navigate to="main"/>},
  {path: '*', element: <NotFound/>}
];

export default routes;