import {useEffect} from "react";

import {useAppDispatch, useAppSelector} from "../../../../store/hooks";

import Button from "../../../BaseComponent/ButtonComponent/Button/Button";

import {
    closeUserCoachMessage,
    getUserCoachMessage,
} from "../../../../store/user/user-coach/user-coach.slice";
import {
    addNotificationMessage,
} from "../../../../store/NotificationList/NotificationList.slice";

import "./UserCoach.scss";

const UserCoach = () => {
    const dispatch = useAppDispatch();

    const userCoachMessage = useAppSelector(getUserCoachMessage);
    const coachAvatarImg = '/coach/avatar.png';

    useEffect(() => {
        if (userCoachMessage) {
            if (userCoachMessage.displayType === 'toNotification') {
                setTimeout(function() {
                    dispatch(closeUserCoachMessage());
                    dispatch(addNotificationMessage({
                        title: userCoachMessage.title,
                        message: userCoachMessage.message,
                        img: coachAvatarImg,
                        reward: userCoachMessage.reward,
                    }));
                }, 3000);
            }
        }

    }, [userCoachMessage]);

    return (
        <div className={'user-coach-container'}>
            {userCoachMessage !== null && <div className={'user-coach'}>
                <div className={'user-coach-message-container'}>
                    <div className={'user-coach-message'}>
                        <div className={'UserCoach-message-title'}>
                            {userCoachMessage.title }
                        </div>
                        <div className={'user-coach-message-label'}
                             dangerouslySetInnerHTML={{ __html: userCoachMessage.message }}
                        ></div>
                        <div className={'user-coach-message-button'}>
                          <Button
                            variant={'primary'}
                            className={'btn-rounded'}
                            gradiant={true}
                            onClick={() => dispatch(closeUserCoachMessage())}
                          >{"Ok"}</Button>
                        </div>
                    </div>
                </div>
                <img className={'user-coach-image'} src={coachAvatarImg} alt={'Coach avatar'}/>
            </div>}
        </div>
    );
};

export default UserCoach;
