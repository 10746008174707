import {MouseEventHandler} from "react";

import "./ProgressBarCircular.scss";

interface ProgressBarCircularProps {
  percentage: number;
  label?: string;
  labelOnHover?: boolean;
  image?: string;
  imageAlt?: string;
  additionalClassName?: string;
  onclick?: MouseEventHandler;
}

const ProgressBarCircular = ({percentage, label, labelOnHover, image, imageAlt, additionalClassName, onclick}: ProgressBarCircularProps) => {
  return (
    <div className={
      'progress-bar-circular'
      + (labelOnHover ? ' progress-bar-circular-label-on-hover' : '')
      + (onclick ? ' progress-bar-circular-link' : '')
      + (additionalClassName? ' ' + additionalClassName : '')
    }
         data-percentage={percentage}
         onClick={onclick}
    >
      <span className="progress-left">
        <span className="progress-bar"></span>
      </span>
      <span className="progress-right">
        <span className="progress-bar"></span>
      </span>
      <div className="progress-bar-circular-content">
        {label && <div className="progress-bar-circular-content-label">{label}</div>}
        {image &&
          <img className={'progress-bar-circular-content-image'}
               src={image}
               alt={imageAlt ? imageAlt : 'Progress bar circular image'}
          />
        }
      </div>
    </div>
  );
};

export default ProgressBarCircular;
