import {useEffect} from "react";

import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
// config.autoAddCss = false;

import AOS from 'aos';

import {useAppSelector} from "../../store/hooks";

import Header from "../../components/PageComponent/LayoutComponent/Header/Header";
// import Footer from "./../../components/page-components/Footer/Footer";

// Home page with the dragon
import User from "../../components/page/user/User";

// Main pages
import Home from "../../components/page/home/Home";
import Learn from "../../components/page/learn/Learn";
import Train from "../../components/page/train/Train";
import Enjoy from "../../components/page/enjoy/Enjoy";
import Quest from "../../components/page/quest/Quest";

import ChallengeList from "../../components/page/challenge-list/ChallengeList";
import Reward from "../../components/page/reward/Reward";
import DailyReward from "../../components/page/daily-reward/DailyReward";

import Profile from "../../components/page/profile/Profile";
import AdventurerGroup from "../../components/page/profile/adventurer-group/AdventurerGroup";
import CoachConsole from "../../components/page/coach-console/CoachConsole";

// Components for all pages
import UserCoach from "../../components/AppComponent/Player/UserCoach/UserCoach";
import NotificationList from "../../components/PageComponent/MessageComponent/NotificationList/NotificationList";

import {
  Pages,
  PageUserTypes,
  getCurrentPage,
  getCurrentPageUserType,
} from "../../store/main/main.slice";
import {
  getIsUserInitialized,
  getIsDailyRewardReceived,
} from "../../store/user/user.slice";

import 'aos/dist/aos.css'; // You can also use <link> for styles
import "./Main.scss";

const Main = () => {
  const isUserInitialized = useAppSelector(getIsUserInitialized);
  const isDailyRewardReceived = useAppSelector(getIsDailyRewardReceived);
  const currentPage = useAppSelector(getCurrentPage);
  const currentPageUserType = useAppSelector(getCurrentPageUserType);

  useEffect(() => {
    // Init AOS
    AOS.init();
    config.autoAddCss = false;
  }, []);

  return (
    <div className={'main'}>
      {isUserInitialized && currentPageUserType === PageUserTypes.Player &&
        <Header/>
      }

      {!isUserInitialized &&
        <User/>
      }

      {isUserInitialized && isDailyRewardReceived && currentPage === Pages.Home &&
        <Home/>
      }
      {isUserInitialized && isDailyRewardReceived && currentPage === Pages.Learn &&
        <Learn/>
      }
      {isUserInitialized && isDailyRewardReceived && currentPage === Pages.Train &&
        <Train/>
      }
      {isUserInitialized && isDailyRewardReceived && currentPage === Pages.Enjoy &&
        <Enjoy/>
      }
      {isUserInitialized && isDailyRewardReceived && currentPage === Pages.Quest &&
        <Quest/>
      }

      {isUserInitialized && currentPage === Pages.Profile &&
        <Profile/>
      }
      {isUserInitialized && currentPage === Pages.AdventurerGroup &&
          <AdventurerGroup/>
      }
      {isUserInitialized && currentPage ===Pages.CoachConsole &&
          <CoachConsole/>
      }

      {isUserInitialized && !isDailyRewardReceived && currentPage !== Pages.Profile && currentPage !== Pages.AdventurerGroup && currentPage !== Pages.CoachConsole &&
        <DailyReward/>
      }
      {isUserInitialized && isDailyRewardReceived && currentPage === Pages.ChallengeList &&
        <ChallengeList/>
      }
      {isUserInitialized && isDailyRewardReceived && currentPage === Pages.Reward &&
        <Reward/>
      }
      {isUserInitialized &&
        <UserCoach/>
      }
      {isUserInitialized && isDailyRewardReceived && currentPage !== Pages.Profile &&
        <NotificationList
          isDisplayMessages={true}
          hasCloseButton={true}
        />
      }
      {/*{isUserInitialized === true && <Footer/>}*/}
    </div>
  );
};

export default Main;
