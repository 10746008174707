import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {Dispatch} from "react";

import {setNotificationDisplayState} from "../../NotificationList/NotificationList.slice";

import {RewardData} from "../../reward/reward.slice";

interface UserCoachMessage {
    message: string;
    code?: string;
    title?: string;
    displayType?: 'displayed'|'toNotification';
    reward?: RewardData;
}

interface UserCoachState {
    message: UserCoachMessage|null;
}

const initialState: UserCoachState = {
    message: null,
};

export const userCoachSlice = createSlice({
    name: "user-coach",
    initialState,
    reducers: {
        closeUserCoachMessage: (state) => {
            state.message = null;
        },
        setUserCoachMessageToState: (state, action) => {
            state.message = action.payload;
        },
    },
});

export const {
    closeUserCoachMessage,
    setUserCoachMessageToState,
} = userCoachSlice.actions;

export const getUserCoachMessage = (state: RootState) => state.userCoach.message;

export const setUserCoachMessage = (message: UserCoachMessage) => (dispatch : Dispatch<any>) => {
    message.code = message.code ?? initCode()
    message.displayType = message.displayType ?? 'displayed'

    dispatch(setNotificationDisplayState('hidden'));

    dispatch(setUserCoachMessageToState(message));
}

export const setUserWelcomeMessage = () => (dispatch : Dispatch<any>) => {
    dispatch(setUserCoachMessage({
        title: "Bienvenue dans Empowered Game",
        message: "Tu peux commencer l'aventure ici",
        displayType: 'toNotification',
    }))
}

const initCode = (): string => {
    return (Date.now().toString() + '_' + Math.floor(Math.random() * 1000000))
}

export default userCoachSlice.reducer;
