import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {Dispatch} from "react";

import {ChallengeData} from "../challenge-list/challenge-list.slice";

interface UserState {
    isInitialized: boolean;
    isDailyRewardReceived: boolean;
    isChallengesToInit: boolean;
    challengesToInit: number;
    username: string;
    challenges: ChallengeData[];
}

const initialState: UserState = {
    isInitialized: false,
    isDailyRewardReceived: false,
    isChallengesToInit: false,
    challengesToInit: 0,
    username: '',
    challenges: [],
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        applyUserChallengeInitialized: (state, action) => {
            // const challengeCode = action.payload.challengeCode;
            const challengeData = action.payload.challengeData;

            state.challenges.push(challengeData);
            state.challengesToInit = state.challengesToInit - 1;

            if (state.challengesToInit === 0) {
                state.isChallengesToInit = false;
                state.isInitialized = true;
            }
        },
        loginUser: (state, action) => {
            state.username = action.payload.userName;
            state.isChallengesToInit = true;
        },
        setIsDailyRewardReceived: (state, action) => {
            state.isDailyRewardReceived = action.payload.isDailyRewardReceived;
        },
        setUserChallengesToInit: (state, action) => {
            state.challengesToInit = action.payload.challengesCount;
        },
        validUserChallengeStep: (state, action) => {
            const challengeCode = action.payload.challengeCode;
            let userChallenge = state.challenges.find(challenge => challenge.code === challengeCode) ?? null;

            if (userChallenge) {
                const challengeItemIndex = action.payload.challengeItemIndex;
                let userChallengeItem = userChallenge.items[challengeItemIndex];
                userChallengeItem.currentStep += 1;
                userChallengeItem.isSuccess = userChallengeItem.currentStep === userChallengeItem.steps.length;

                let userChallengesItemsToFinish = 0;
                userChallenge.items.forEach((userChallengeItem) => {
                    if (!userChallengeItem.isSuccess) {
                        userChallengesItemsToFinish += 1;
                    }
                });
                userChallenge.isSuccess = userChallengesItemsToFinish === 0;
            }
        },
    },
});

export const {
    applyUserChallengeInitialized,
    loginUser,
    setIsDailyRewardReceived,
    setUserChallengesToInit,
    validUserChallengeStep,
} = userSlice.actions;

export const getIsUserChallengesToInit = (state: RootState) => state.user.isChallengesToInit;
export const getIsUserInitialized = (state: RootState) => state.user.isInitialized;
export const getUserUsername = (state: RootState) => state.user.username;
export const getIsDailyRewardReceived = (state: RootState) => state.user.isDailyRewardReceived;
export const getUserChallengeByCode = (challengeCode: string) => (state: RootState): ChallengeData|null => {
    return state.user.challenges.find(challenge => challenge.code === challengeCode) ?? null;
};
export const getUserChallengesByCodes = (challengesCodes: string[]) => (state: RootState): ChallengeData[] => {
    let challenges: ChallengeData[] = [];

    challengesCodes.forEach(challengeCode => {
        const userChallenge = state.user.challenges.find(challenge => challenge.code === challengeCode) ?? null;
        if (userChallenge) {
            challenges.push(userChallenge);
        }
    });

    return challenges;
};
// export const validUserChallengeStep = (challengeCode: string, challengeItemIndex: number) => (dispatch : Dispatch<any>): void => {
//     let userChallenge = dispatch(getUserChallengeByCode(challengeCode));
//
//
//
//     // let userChallenge = dispatch(getUserChallengeByCode(challengeCode));
//     //
//         console.log('validUserChallengeStep');
//         console.log(userChallenge);
// };


export const requestAllChallenges = (initChallenges: boolean) => async (dispatch : Dispatch<any>) => {
    if (!initChallenges) {
        return false;
    }

    const challengeCodes = [
        'archery-combat-training',
        'horse-combat-training',
        'sword-combat-training',
    ];

    dispatch(setUserChallengesToInit({'challengesCount' : challengeCodes.length}));

    challengeCodes.forEach(challengeCode => {
        dispatch(requestChallenge(challengeCode));
    });
};
export const requestChallenge = (challengeCode: string) => async (dispatch : Dispatch<any>) => {
    try {
        const challengeCodeSplitArray = challengeCode.split('-');
        const challengeUrl = '/db_data_tmp/challenges/' + challengeCodeSplitArray[2] + '/' + challengeCodeSplitArray[1] + '/' + challengeCodeSplitArray[0] + '.json';
        await fetch(challengeUrl).then(function(response) {
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return response.json().then(function(json) {
                    dispatch(applyUserChallengeInitialized({'challengeCode' : challengeCode, 'challengeData' : json}));
                });
            } else {
                console.error("Json not found in url: " + challengeUrl);
                // TODO dispatch an event to redirect on previous page with error message can not load challenge
            }
        });
    } catch (e) {
        // TODO dispatch an event to redirect on previous page with error message can not load challenge
    }
};

export default userSlice.reducer;
