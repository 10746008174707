import * as React from "react";
import {MouseEventHandler} from "react";

import * as ReactBootstrap from "react-bootstrap";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCrown,
} from "@fortawesome/free-solid-svg-icons";

import Card from "../../../BaseComponent/CardComponent/Card/Card";

import {Player} from "../../../../store/PlayerGroup/PlayerGroup.slice";

import './PlayerCard.scss'

interface PlayerCardProps {
    player: Player;
    isActivePlayer: boolean;
    dataAos?: string;
    onClick?: MouseEventHandler;
}

const PlayerCard = ({player, isActivePlayer, dataAos, onClick}: PlayerCardProps) => {
    return (
        <Card
            className={'card-transparent player-card' + (player.isLoggedUser ? ' player-card-avatar-is-logged-user' : '') + (isActivePlayer ? ' active' : '')}
            dataAos={dataAos}
            onClick={onClick}
        >
            <ReactBootstrap.Card.Body>
                <div className={'player-card-avatar'}>
                    <div className={'player-card-avatar-image'}>
                        <img className={'player-card-avatar-img'}
                             src={player.avatar}
                             alt={player.pseudo + ' avatar'}
                             width={100}
                             height={100}
                        />
                        {player.isLeader &&
                          <div className={'player-card-avatar-leader'}>
                            <FontAwesomeIcon
                              icon={faCrown}
                              fixedWidth
                            />
                          </div>
                        }
                        <div className={'player-card-avatar-level'}>
                            <ReactBootstrap.Badge pill bg="danger">{player.level}</ReactBootstrap.Badge>
                        </div>
                    </div>
                </div>
                <div className={'player-card-pseudo'}>{player.pseudo}</div>
            </ReactBootstrap.Card.Body>
        </Card>
    )
}

export default PlayerCard;
