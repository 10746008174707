import {useAppDispatch} from "../../../store/hooks";

import PageHeader from "../../PageComponent/LayoutComponent/PageHeader/PageHeader";

import {
    goToHome,
} from "../../../store/main/main.slice";

import "./Quest.scss";

const Quest = () => {
    const dispatch = useAppDispatch();
    return (
        <div className={'main-content quest'}>
            <div className={'main-page-content-container'}>
                <PageHeader label={"Réaliser une quête"} backLabel={"Retour"} backOnclick={() => dispatch(goToHome())} />
                <div className={'page-content'}>
                    Contenu à venir
                </div>
            </div>
        </div>
    );
};

export default Quest;
