import { configureStore } from '@reduxjs/toolkit'
import { reducer as formReducer } from 'redux-form';

import counterReducer from "./counter/counter.slice"
import mainReducer from "./main/main.slice"
import homeReducer from "./home/home.slice"
import trainReducer from "./train/train.slice"
import challengeListReducer from "./challenge-list/challenge-list.slice"
import rewardReducer from "./reward/reward.slice"
import userReducer from "./user/user.slice"
import userCoachReducer from "./user/user-coach/user-coach.slice"
import dailyRewardReducer from "./user/daily-reward/daily-reward.slice"
import NotificationListReducer from "./NotificationList/NotificationList.slice"
import PlayerGroupSlice from "./PlayerGroup/PlayerGroup.slice"
import CoachPlayerListSlice from "./coach/player-list/CoachPlayerList.slice"
import CoachRecommendationListSlice from "./coach/recommendation-list/CoachRecommendationList.slice";

export const store = configureStore({
  reducer: {
    main: mainReducer,
    user: userReducer,
    userCoach: userCoachReducer,
    dailyRewards: dailyRewardReducer,
    home: homeReducer,
    train: trainReducer,
    challengeList: challengeListReducer,
    reward: rewardReducer,
    form: formReducer,
    notificationList: NotificationListReducer,
    playerGroup: PlayerGroupSlice,
    coachPlayerGroup: CoachPlayerListSlice,
    coachRecommendationGroup: CoachRecommendationListSlice,
    counter: counterReducer,
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch