import {createSlice} from "@reduxjs/toolkit";

import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

import {RootState} from "../../store";

export interface CoachPlayer {
  id: number;
  pseudo: string;
  avatar: string;
  fullName: string;
  progress: {};
  successfulWeeks: number;
  actions: CoachPlayerAction[];
}

export interface CoachPlayerAction {
  code: string;
  label: string;
  icon: IconDefinition|undefined;
}



interface CoachPlayerListState {
  players: CoachPlayer[];
}

const initialState: CoachPlayerListState = {
  players: [],
};

export const CoachPlayerListSlice = createSlice({
  name: "coach-player-group",
  initialState,
  reducers: {
    setCoachPlayersToState: (state, action) => {
      state.players = action.payload;
    },
  },
})

export const {
  setCoachPlayersToState,
} = CoachPlayerListSlice.actions;

export const getCoachPlayers = (state: RootState) => state.coachPlayerGroup.players;

export default CoachPlayerListSlice.reducer;
