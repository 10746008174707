import {useEffect} from "react";

import {useAppDispatch, useAppSelector} from "../../../store/hooks";

import {
  getIsUserChallengesToInit,
  getIsUserInitialized,
  getIsDailyRewardReceived,
  loginUser,
  requestAllChallenges,
} from "../../../store/user/user.slice";

import Button from "../../BaseComponent/ButtonComponent/Button/Button";

import "./User.scss";

const User = () => {
  const dispatch = useAppDispatch();

  const isUserChallengesToInit = useAppSelector(getIsUserChallengesToInit);
  const isUserInitialized = useAppSelector(getIsUserInitialized);
  const isDailyRewardReceived = useAppSelector(getIsDailyRewardReceived);

  useEffect(() => {
    dispatch(requestAllChallenges(isUserChallengesToInit));
  }, [dispatch, isUserChallengesToInit]);

  // useEffect(() => {
  //   // if (isDailyRewardReceived) {
  //     // dispatch(setUserWelcomeMessage());
  //   // }
  // }, [dispatch, isUserInitialized]);

  return (
    <div className={'main-content user'}>
      <img
        className={'main-background-image'}
        src={'/main/background.jpg'}
        alt={'Main background'}
        data-aos="fade-down"
        data-aos-offset="0"
      />
      <div className={'main-overlay-content'}>
        <div className={'main-page-content-container'}>
          <div className={'page-content'}>
            <div className={'block1'}>

            </div>
            <div className={'block2'}>
              {!isUserChallengesToInit &&
                <h1
                  className={"main-title"}
                >
                  <span
                    className={"main-title-first"}
                    data-aos="fade-down"
                    data-aos-offset="0"
                  >Bienvenue dans le thème</span>
                  <span
                    className={"main-title-second"}
                    data-aos="zoom-in"
                    data-aos-offset="0"
                  >DRAGONIA</span>
                </h1>
              }
              {isUserChallengesToInit && <div className={'loading'}>{"Chargement..."}</div>}
            </div>
            <div className={'block3'}>
              {!isUserChallengesToInit &&
                <Button
                    variant={'primary'}
                    size={'lg'}
                    className={'btn-rounded'}
                    gradiant={true}
                    onClick={() => dispatch(loginUser({userName: 'David'}))}
                >{"Débuter l'aventure"}</Button>
              }
              {isUserChallengesToInit &&
                <div>{"Chargement..."}</div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
