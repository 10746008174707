import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";

export interface RewardData {
    diamonds: number;
    goldCoins: number;
    potions: number;
    experience: number;
}

interface RewardState {
    diamonds: number;
    goldCoins: number;
    potions: number;
    experience: number;
}

const initialState: RewardState = {
    diamonds: 0,
    goldCoins: 0,
    potions: 0,
    experience: 0,
};

export const rewardSlice = createSlice({
    name: "main",
    initialState,
    reducers: {
        initReward: (state, action: PayloadAction<RewardData>) => {
            const reward = action.payload;
            state.diamonds = reward.diamonds;
            state.goldCoins = reward.goldCoins;
            state.potions = reward.potions;
            state.experience = reward.experience;
        },
    },
});

export const {
    initReward,
} = rewardSlice.actions;

export const getRewardDiamonds = (state: RootState) => state.reward.diamonds;
export const getRewardGoldCoins = (state: RootState) => state.reward.goldCoins;
export const getRewardPotions = (state: RootState) => state.reward.potions;
export const getRewardExperience = (state: RootState) => state.reward.experience;

export default rewardSlice.reducer;