import {useAppDispatch, useAppSelector} from "../../../store/hooks";

import PageHeader from "../../PageComponent/LayoutComponent/PageHeader/PageHeader";
import GameActionCard from "../../Module/Game/Card/ActionCard/GameActionCard";
import DownRibbon from "../../BaseComponent/ribbon-components/down-ribbon/DownRibbon";

import {
  goToLearn,
  goToTrain,
  goToEnjoy,
  goToQuest,
} from "../../../store/main/main.slice";
import {
  goToTrainHomePage,
} from "../../../store/train/train.slice";
import {
  closeUserCoachMessage,
  setUserCoachMessage,
} from "../../../store/user/user-coach/user-coach.slice";
import {
  getUserChallengesByCodes,
} from "../../../store/user/user.slice";
import {
  getChallengesItemsToFinishFromChallenges,
} from "../../../store/challenge-list/challenge-list.slice";

import "./Home.scss";

const Home = () => {
  const dispatch = useAppDispatch();

  const learn = () => {
    dispatch(closeUserCoachMessage());
    dispatch(goToLearn());
  };
  const train = () => {
    dispatch(closeUserCoachMessage());
    dispatch(goToTrain());
    dispatch(goToTrainHomePage());
  };
  const enjoy = () => {
    dispatch(closeUserCoachMessage());
    dispatch(goToEnjoy());
  };
  const quest = () => {
    dispatch(closeUserCoachMessage());
    dispatch(goToQuest());
  };

  const userTrainChallenges = useAppSelector(getUserChallengesByCodes(['archery-combat-training', 'sword-combat-training', 'horse-combat-training']));
  const userTrainChallengesItemsToFinish = useAppSelector(getChallengesItemsToFinishFromChallenges(userTrainChallenges));

  return (
    <div className={'main-content home'}>
      <div className={'main-background-image-container'}
           // data-aos="fade-down"
           // data-aos-offset="0"
      >
        <img className={'main-background-image'}
             src={'/home/home-background.jpeg'}
             alt={'Home background image'}
        />
      </div>
      <div className={'main-overlay-content'}>
        <div className={'main-page-content-container'}>
          <PageHeader label={'Cité impériale'}
                      align={'start'}
          />
          <div className={'page-content'}>
            <div className={'container-fluid'}>
              <div className={'row g-2 my-2 align-items-center justify-content-around'}>
                <div
                  className={'col-12 col-sm-6 col-md-4 col-lg-2'}
                  data-aos="fade-left"
                  data-aos-offset="0"
                >
                  <GameActionCard
                    title={'Apprendre'}
                    imageSrc={'/home/learn.png'}
                    imageAlt={'Image Apprendre'}
                    color={'darkblue'}
                    isBuilding={true}
                    // onClick={learn}
                  />
                </div>
                <div
                  className={'col-12 col-sm-6 col-md-4 col-lg-2'}
                  data-aos="fade-left"
                  data-aos-offset="0"
                >
                  <GameActionCard
                    title={'S\'entraîner'}
                    imageSrc={'/home/train.png'}
                    imageAlt={'Image S\'entraîner'}
                    color={'green'}
                    remainingCount={userTrainChallengesItemsToFinish}
                    onClick={train}
                  />
                </div>
                <div
                  className={'col-12 col-sm-6 col-md-4 col-lg-2'}
                  data-aos="fade-left"
                  data-aos-offset="0"
                >
                  <GameActionCard
                    title={'S\'amuser'}
                    imageSrc={'/home/enjoy.png'}
                    imageAlt={'Image S\'amuser'}
                    color={'purple'}
                    isBuilding={true}
                    // onClick={enjoy}
                  />
                </div>
                <div
                  className={'col-12 col-sm-6 col-md-4 col-lg-2'}
                  data-aos="fade-left"
                  data-aos-offset="0"
                >
                  <GameActionCard
                    title={'Réaliser une quête'}
                    imageSrc={'/home/quest.png'}
                    imageAlt={'Image Réaliser une quête'}
                    color={'red'}
                    isBuilding={true}
                    // onClick={quest}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
