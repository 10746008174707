import {useAppDispatch, useAppSelector} from "../../../store/hooks";

import PageHeader from "../../PageComponent/LayoutComponent/PageHeader/PageHeader";
import DownRibbon from "../../BaseComponent/ribbon-components/down-ribbon/DownRibbon";

import {
  goToHome,
  goToChallengeList,
} from "../../../store/main/main.slice";
import {
  goToTrainHomePage,
} from "../../../store/train/train.slice";
import {
  initChallenge,
  getChallengeItemsToFinishFromChallenge,
} from "../../../store/challenge-list/challenge-list.slice";
import {
  getUserChallengeByCode,
} from "../../../store/user/user.slice";

import "./Train.scss";

const Train = () => {
  const dispatch = useAppDispatch();

  const startArcheryCombatTrainingAction = () => {
    dispatch(initChallenge({'challenge': userArcheryChallenge}));
    dispatch(goToChallengeList());
  };
  const startSwordCombatTrainingAction = () => {
    dispatch(initChallenge({'challenge': userSwordChallenge}));
    dispatch(goToChallengeList());
  };
  const startHorseCombatTrainingAction = () => {
    dispatch(initChallenge({'challenge': userHorseChallenge}));
    dispatch(goToChallengeList());
  };

  const userArcheryChallenge = useAppSelector(getUserChallengeByCode('archery-combat-training'));
  const userSwordChallenge = useAppSelector(getUserChallengeByCode('sword-combat-training'));
  const userHorseChallenge = useAppSelector(getUserChallengeByCode('horse-combat-training'));

  const archeryChallengeItemsToFinish = useAppSelector(getChallengeItemsToFinishFromChallenge(userArcheryChallenge));
  const swordChallengeItemsToFinish = useAppSelector(getChallengeItemsToFinishFromChallenge(userSwordChallenge));
  const horseChallengeItemsToFinish = useAppSelector(getChallengeItemsToFinishFromChallenge(userHorseChallenge));

  return (
    <div className={'main-content'}>
      <div className={'main-content-2'}>
        <img className={'main-background-image'} src={'/train/combat-training/weapon_room.jpg'} alt={'Weapon room'}/>
        <div className={'main-overlay-content'}>
          <div className={'main-page-content-container'}>
            <PageHeader label={"Entraînement au combat"}
                        backLabel={"Retour"}
                        backOnclick={() => dispatch(goToHome())}
            />
            <div className={'page-content'}>
              <div className={'container-fluid'}>
                <div className={'row g-2 my-2 align-items-center justify-content-around'}>
                  <div
                    className={'col-12 col-sm-6 col-md-4 col-lg-2'}
                    data-aos="fade-left"
                    data-aos-offset="0"
                  >
                    <div className={'main-card-action card card-color-darkblue'}
                         onClick={startArcheryCombatTrainingAction}>
                      {archeryChallengeItemsToFinish === 0 && <DownRibbon label={"V"} isSuccess={true}/>}
                      {archeryChallengeItemsToFinish > 0 &&
                        <DownRibbon label={archeryChallengeItemsToFinish.toString()} isSuccess={false}/>}
                      <img className={'main-card-img card-img-top'} src={'/train/combat-training/archery.jpg'}
                           alt={'Archery combat'}/>
                      <div className={'main-card-title card-title'}>Combat à l'arc</div>
                    </div>
                  </div>
                  <div
                    className={'col-12 col-sm-6 col-md-4 col-lg-2'}
                    data-aos="fade-left"
                    data-aos-offset="0"
                  >
                    <div className={'main-card-action card card-color-green'} onClick={startSwordCombatTrainingAction}>
                      {swordChallengeItemsToFinish === 0 && <DownRibbon label={"V"} isSuccess={true}/>}
                      {swordChallengeItemsToFinish > 0 &&
                        <DownRibbon label={swordChallengeItemsToFinish.toString()} isSuccess={false}/>}
                      <img className={'main-card-img card-img-top'} src={'/train/combat-training/sword.jpg'}
                           alt={'Sword combat'}/>
                      <div className={'main-card-title card-title'}>Combat à l'épée</div>
                    </div>
                  </div>
                  <div
                    className={'col-12 col-sm-6 col-md-4 col-lg-2'}
                    data-aos="fade-left"
                    data-aos-offset="0"
                  >
                    <div className={'main-card-action card card-color-red'} onClick={startHorseCombatTrainingAction}>
                      {horseChallengeItemsToFinish === 0 && <DownRibbon label={"V"} isSuccess={true}/>}
                      {horseChallengeItemsToFinish > 0 &&
                        <DownRibbon label={horseChallengeItemsToFinish.toString()} isSuccess={false}/>}
                      <img className={'main-card-img card-img-top'} src={'/train/combat-training/horse_combat.jpg'}
                           alt={'Horse combat'}/>
                      <div className={'main-card-title card-title'}>Combat à cheval</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Train;
