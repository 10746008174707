import "./RewardItems.scss";

interface RewardItemsProps {
  additionalClassName?: string;
  diamonds: number;
  goldCoins: number;
  experience: number;
}

const RewardItems = ({diamonds, goldCoins, experience, additionalClassName}: RewardItemsProps) => {
  return (
    <div className={'reward-items' + (additionalClassName ? ' ' + additionalClassName : '')}>
      <div className={'reward-line'}>
        {diamonds > 0 &&
          <div className={'reward-item reward-item-gems'}>
            <div className={'reward-item-label'}>+{diamonds}</div>
            <img className={'reward-item-image'}
                 src={'/object/diamond.png'}
                 alt={'Diamond'}
            />
          </div>
        }
        {goldCoins > 0 &&
          <div className={'reward-item reward-item-gold-coins'}>
            <div className={'reward-item-label'}>+{goldCoins}</div>
            <img className={'reward-item-image'}
                 src={'/object/gold_coin.png'}
                 alt={'Gold coin'}
            />
          </div>
        }
        {experience > 0 &&
          <div className={'reward-item reward-item-experience'}>
            <div className={'reward-item-label'}>+{experience}</div>
            <img className={'reward-item-image'}
                 src={'/object/experience.png'}
                 alt={'Experience'}
            />
          </div>
        }
      </div>
    </div>
  );
};

export default RewardItems;
